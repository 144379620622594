import { InputAdornment, TextField } from '@mui/material';
import { Search } from '@mui/icons-material';
import { KeyboardEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Table } from '@tanstack/react-table';
import { Virtualizer } from '@tanstack/react-virtual';
import { SEARCH_TEXT_URL_PARAM } from 'utils/constants';
import { extractExistingParams } from 'pages/Catalog/constants';
import { debounce } from 'utils/commonFunctions';
import { CatalogItem } from 'slices/catalogSlice';

const SEARCH_DEBOUNCE_DELAY = 700;

const TableSearch = ({
  table,
  rowVirtualizer
}: {
  table: Table<CatalogItem>;
  rowVirtualizer: Virtualizer<HTMLDivElement, Element>;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const rows = table.getRowModel().rows;
  const [searchValue, setSearchValue] = useState<string>(
    searchParams.get(SEARCH_TEXT_URL_PARAM) || ''
  );
  useEffect(() => {
    if (searchValue.length > 0) handleChangeSearch(searchValue);
  }, [table.getState().sorting]);

  const debouncedSetGlobalFilter = useMemo(
    () =>
      debounce((inputValue: string) => {
        table.setGlobalFilter(inputValue);
        updateSearchParams(inputValue);
      }, SEARCH_DEBOUNCE_DELAY),
    [rows, rowVirtualizer]
  );
  const updateSearchParams = (value: string) => {
    let existingParams = extractExistingParams(searchParams);
    if (value === '') {
      delete existingParams[SEARCH_TEXT_URL_PARAM];
    } else {
      existingParams = { ...existingParams, [SEARCH_TEXT_URL_PARAM]: value };
    }
    setSearchParams({ ...searchParams, ...existingParams });
  };

  const handleChangeSearch = (value: string) => {
    setSearchValue(value);
    debouncedSetGlobalFilter(value);
  };
  const inputRef = useRef<HTMLInputElement>();

  return (
    <TextField
      inputRef={inputRef}
      hiddenLabel
      value={searchValue}
      onChange={e => handleChangeSearch(e.target.value)}
      onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') handleChangeSearch(searchValue);
      }}
      sx={{
        minWidth: 200,
        '& .MuiFilledInput-input': {
          p: 1.5
        },
        '& .MuiInputBase-root': {
          backgroundColor: 'secondary.main',
          height: 48,
          maxHeight: 48
        }
      }}
      slotProps={{
        input: {
          startAdornment: (
            <InputAdornment
              onClick={() => {
                if (inputRef.current) inputRef.current.focus();
              }}
              sx={{ m: 0 }}
              position='start'
            >
              <Search color='info' />
            </InputAdornment>
          )
        }
      }}
      variant='filled'
      placeholder='Search'
    />
  );
};

export default TableSearch;

import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import { RootState } from '../../store';
import InsightsSkeleton from './InsightsSkeleton';
import InsightsComponents from './InsightsComponents';
import useInsights from './useInsights';
import { useAppSelector } from 'hooks/redux-hooks';
import { selectInsightsStatus } from 'slices/insightsSlice';
import MainLayout from 'layouts/MainLayout';
import Tabs from 'components/Tabs';
import ShareButton from 'pages/Insights/ShareButton';

const Insights = () => {
  const navigate = useNavigate();
  const params = useParams();
  const status = useAppSelector(selectInsightsStatus);
  const { insights } = useSelector((state: RootState) => state.insights);
  const { activeTab, setActiveTab } = useInsights();

  const tabNameExists = (tabName: string) => {
    // check if the tabname exists
    return insights?.pages.some(({ name }) => getTabUrl(name) === tabName);
  };

  const getActiveTabIdx = (tabName: string) => {
    // find index of the tabname
    const idx = insights?.pages.findIndex(({ name }) => getTabUrl(name) === tabName) || 0;
    return idx > -1 ? idx : 0;
  };

  const getTabUrl = (tabName: string) => {
    // remove all non-alphanumeric characters
    return tabName.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
  };

  const onChange = (index: number) => {
    setActiveTab(index);
    const tabName = getTabUrl(insights?.pages[index]?.name || '');
    navigate(`/markets/${params.insightId}/${tabName}`);
  };

  useEffect(() => {
    let tabName = getTabUrl(params?.tabId || insights?.pages[0]?.name || '');

    if (insights?.pages[0]?.name && !tabNameExists(tabName)) {
      tabName = getTabUrl(insights?.pages[0]?.name);
    }

    setActiveTab(getActiveTabIdx(tabName));
    if (params?.insightId) navigate(`/markets/${params.insightId}/${tabName}`);

    if (insights?.metadata?.displayName) {
      document.title = 'GX Go | ' + insights.metadata.displayName;
    }
    return () => {
      document.title = 'GX Go';
    };
  }, [insights]);

  return (
    <MainLayout>
      {insights?.pages && status !== 'loading' && status !== 'idle' ? (
        <>
          <Box
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 3 }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                variant='h2'
                sx={{ mr: 6 }}
              >
                {insights?.metadata?.displayName}
              </Typography>
              <Tabs
                mainPageTitle={insights?.metadata?.displayName}
                tabs={insights?.pages.map(({ name }) => ({ name }))}
                activeTab={insights?.pages.length <= activeTab ? 0 : activeTab}
                onChange={onChange}
              />
            </Box>
            <ShareButton
              title={insights?.metadata?.displayName}
              additionalTitle={insights?.pages[activeTab]?.name}
            />
          </Box>
          <InsightsComponents
            marketTitle={insights?.metadata?.displayName}
            pageTitle={insights?.pages[activeTab]?.name}
            components={insights?.pages[activeTab]?.components}
          />
        </>
      ) : (
        <InsightsSkeleton />
      )}
    </MainLayout>
  );
};

export default Insights;

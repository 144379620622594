import { Box, Button } from '@mui/material';

const StepButtons = ({
  activeStep,
  onNextClick,
  onBackClick,
  nextDisabled
}: {
  activeStep: number;
  onBackClick: () => void;
  onNextClick: () => void;
  nextDisabled?: boolean;
}) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
      <Button
        fullWidth
        variant='outlined'
        disabled={activeStep === 0}
        sx={{
          '&.MuiButton-outlined': {
            color: 'primary.main',
            borderColor: 'primary.main'
          },
          fontSize: 16,
          fontWeight: 600,
          mr: 1
        }}
        onClick={onBackClick}
      >
        Back
      </Button>
      <Button
        fullWidth
        variant='contained'
        disabled={nextDisabled}
        sx={{
          color: 'background.default',
          fontSize: 16,
          fontWeight: 600
        }}
        onClick={onNextClick}
      >
        Next
      </Button>
    </Box>
  );
};
export default StepButtons;

import { ReactNode, SyntheticEvent } from 'react';
import { Tabs as MuiTabs, Tab, Box, IconButton } from '@mui/material';
import posthog from 'posthog-js';
import CloseIcon from '@mui/icons-material/Close';

interface Tab {
  name: string;
  disabled?: boolean;
  label?: ReactNode;
  closable?: boolean;
  onClose?: (value: number) => void;
}

interface CustomTabsProps {
  mainPageTitle?: string | undefined;
  subPageTitle?: string | undefined;
  tabs: Tab[];
  activeTab: number;
  onChange?: (index: number) => void;
  height?: number;
  minTabWidth?: number;
  offset?: number;
  maxTabWidth?: number | 'unset';
}

const CloseButton = ({
  label,
  name,
  onClose,
  index
}: {
  label: ReactNode | undefined;
  index: number;
  name: string;
  onClose?: (value: number) => void;
}) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {label || name}

      <IconButton
        onClick={e => {
          e.stopPropagation();
          if (onClose) onClose(index);
        }}
        sx={{
          ml: 1,
          width: 32,
          height: 32,
          padding: 0.5
        }}
      >
        <CloseIcon sx={{ fontSize: 16 }} /> {/* Smaller icon */}
      </IconButton>
    </Box>
  );
};

const CustomTabs = ({
  mainPageTitle,
  subPageTitle,
  tabs,
  activeTab,
  onChange,
  height = 48,
  minTabWidth = 120,
  maxTabWidth = 'unset',
  offset = 0,
  ...tabProps
}: CustomTabsProps) => {
  const handleChange = (event: SyntheticEvent, newValue: number) => {
    if (onChange) onChange(newValue);
  };

  const handleTabClick = (
    mainPage: string | undefined,
    subPage: string | undefined,
    tabName: string
  ) => {
    posthog.capture('tab_click', {
      mainPage: mainPage,
      subPage: subPage,
      tabName: tabName
    });
  };

  return (
    <MuiTabs
      value={activeTab}
      onChange={handleChange}
      aria-label='tabs'
      variant='scrollable'
      scrollButtons='auto'
      sx={{ maxWidth: `calc(100% - ${offset}px)`, minHeight: `${height}px`, height: height }}
    >
      {tabs?.map(({ name, label, disabled, closable, onClose }, index) => (
        <Tab
          onClick={() => {
            handleTabClick(mainPageTitle, subPageTitle, name);
          }}
          label={
            closable ? (
              <CloseButton
                label={label}
                name={name}
                index={index}
                onClose={onClose}
              />
            ) : (
              label || name
            )
          }
          key={`tab-${name}-${index}`}
          id={`tab-${name}`}
          aria-controls={`tabpanel-${name}`}
          disabled={disabled}
          sx={{
            textTransform: 'initial',
            height: height,
            minWidth: `${minTabWidth}px`,
            maxWidth: `${maxTabWidth}px`,
            minHeight: `${height}px`,
            p: 1,
            '&:not(:last-child)': { mr: '2px' }
          }}
          {...tabProps}
        />
      ))}
    </MuiTabs>
  );
};

export default CustomTabs;

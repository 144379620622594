import { Box, Stack, Typography } from '@mui/material';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import GoLogo from 'components/GoLogo';
import OnboardingLayout from 'layouts/OnboardingLayout';
import Loader from 'components/Loader';
import useOnboardingForm from 'pages/Onboarding/OnboardingForm/useOnboardingForm';
import { ThanksMessage } from 'pages/Onboarding';

const OnboardingForm = () => {
  const { activeStep, steps, refreshReCaptcha, loading, failed, onVerify } = useOnboardingForm();
  return (
    <OnboardingLayout>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          mt: '8%',
          ml: 4
        }}
      >
        {failed && <ThanksMessage />}
        {loading && (
          <Box>
            <Typography sx={{ fontSize: 24, fontWeight: 'bold', mb: 1.5 }}>
              You’re all set!
            </Typography>
            <Typography sx={{ fontSize: 24, fontWeight: 'bold', mb: 1.5 }}>
              You're now ready to (GX) go!
            </Typography>{' '}
            <Loader></Loader>
          </Box>
        )}
        {!loading && !failed && (
          <Box>
            <GoLogo open={true} />
            <Box sx={{ maxWidth: 650, mt: 3 }}>
              <GoogleReCaptcha
                onVerify={onVerify}
                action='onboardingForm'
                refreshReCaptcha={refreshReCaptcha}
              />
              <Box sx={{ mb: 2 }}>
                {' '}
                <Typography
                  sx={{
                    mb: 0.5,
                    color: 'info.dark',
                    fontSize: 12
                  }}
                >{`Step ${activeStep + 1} of ${steps.length}`}</Typography>
                <Stack
                  sx={{ width: '100%' }}
                  direction={'row'}
                  spacing={1}
                >
                  {steps.map((_, index) => {
                    return (
                      <Box
                        sx={{
                          height: 4,
                          width: 88,
                          backgroundColor: activeStep >= index ? 'primary.main' : 'info.dark'
                        }}
                      ></Box>
                    );
                  })}
                </Stack>
              </Box>
              {steps[activeStep]?.component}
            </Box>
          </Box>
        )}
      </Box>
    </OnboardingLayout>
  );
};

export default OnboardingForm;

import { useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts/highstock';
import { ChartSeries } from '../../useChartSeries';
import useFullScreenOptions from './useFullScreenOptions';
import { ChartParentComponent } from 'pages/Insights/InsightsComponents/ChartsBlock/useChartFunctions';

const useFullScreenChart = ({
  chartSeries,
  isLaunchpad,
  parent
}: {
  chartSeries: ChartSeries[] | undefined;
  isLaunchpad?: boolean;
  parent: ChartParentComponent;
}) => {
  const chartHeight = window.innerHeight - (isLaunchpad ? 424 : 260);
  const { createOptions } = useFullScreenOptions({ height: chartHeight, isLaunchpad });

  const getOptions = () => {
    return chartSeries && createOptions(chartSeries, parent);
  };
  const options = chartSeries ? getOptions() : null;

  const chartComponentRef = useRef(null);
  const [chartObject, setChartObject] = useState<Highcharts.Chart>();
  useEffect(() => {
    if (chartComponentRef.current) {
      const chartRef: any = chartComponentRef.current;
      setChartObject(chartRef.chart);
    }
  }, [chartComponentRef.current, chartSeries]);

  return { chartObject, options, chartComponentRef };
};

export default useFullScreenChart;

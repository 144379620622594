import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { Box, Typography } from '@mui/material';
import { ChartSeries } from '../../useChartSeries';
import LegendDropdown from './LegendDropdown';
import useStockChart from './useStockChart';
import { DEFAULT_CHART_HEIGHT, TABLE_ROW_HEIGHT_PX } from 'utils/constants';
import Loader from 'components/Loader';
import { ChartParentComponent } from 'pages/Insights/InsightsComponents/ChartsBlock/useChartFunctions';

const StockChart = ({
  chartSeries,
  height,
  error,
  loading,
  parent,
    showLegend= true
}: {
  chartSeries: ChartSeries[] | undefined;
  height?: number;
  error: boolean;
  loading: boolean;
  parent: ChartParentComponent;
  showLegend?: boolean;
}) => {
  const { options, chartObject, chartComponentRef } = useStockChart({
    chartSeries,
    height,
    parent
  });

  const chartContainerHeight = height ? height * TABLE_ROW_HEIGHT_PX : DEFAULT_CHART_HEIGHT;

  if (error)
    return (
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          height: chartContainerHeight
        }}
      >
        <Typography color='info'>
          We couldn't retrieve your data. Please try again later.
        </Typography>
      </Box>
    );
  if (loading || !chartSeries?.length)
    return (
      <Box sx={{ height: chartContainerHeight }}>
        <Loader />
      </Box>
    );

  if (chartSeries?.length) {
    return (
      <Box className='stock-chart'>
        <HighchartsReact
          highcharts={Highcharts}
          constructorType={'stockChart'}
          options={options}
          ref={chartComponentRef}
        />
        {chartObject?.series && showLegend && <LegendDropdown chart={chartObject} />}
      </Box>
    );
  }
};
export default StockChart;

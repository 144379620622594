import { Dispatch, SetStateAction } from 'react';
import { Box, List, ListItemButton, ListItemText, Typography } from '@mui/material';
import StepButtons from 'pages/Onboarding/OnboardingForm/Steps/StepButtons';

const DefaultMarket = ({
  market,
  setMarket,
  activeStep,
  setActiveStep
}: {
  market: string;
  setMarket: Dispatch<SetStateAction<string>>;
  activeStep: number;
  setActiveStep: Dispatch<SetStateAction<number>>;
}) => {
  const markets = [
    { value: 'Oil', key: 'oil' },
    { value: 'Crude', key: 'crude' },
    { value: 'Refined Products', key: 'refinedproducts' },
    { value: 'LPG/NGLs', key: 'lpgngls' },
    { value: 'Gasoline', key: 'gasoline' },
    { value: 'Aviation', key: 'aviation' },
    { value: 'Biofuels & Transition Fuels', key: 'biofuelstransitionfuels' },
    { value: 'Carbon', key: 'carbon' },
    { value: 'Americas', key: 'americas' },
    { value: 'Europe & Africa', key: 'europeafrica' },
    { value: 'Middle East & Asia', key: 'middleeastasia' }
  ];
  return (
    <Box>
      <Typography sx={{ fontSize: 24, fontWeight: 'bold', mb: 1.5 }}>
        Choose your starting screen...
      </Typography>
      <List
        aria-label='market-selectlist'
        sx={{
          maxHeight: '60vh',
          overflowY: 'scroll',
          pr: 2,
          '&::-webkit-scrollbar': {
            width: '8px'
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: '12px',
            backgroundColor: '#6e729c',
            backgroundClip: 'content-box',
            height: ' 70px'
          }
        }}
      >
        {markets.map((item, index) => {
          return (
            <ListItemButton
              key={item.key + index}
              sx={{
                backgroundColor: 'rgba(248, 248, 248, 0.20)',
                mb: 2,
                '&.Mui-selected': {
                  border: '2px solid #E84E0F'
                }
              }}
              selected={market === item.key}
              onClick={() => {
                setMarket(item.key);
              }}
            >
              <ListItemText primary={item.value} />
            </ListItemButton>
          );
        })}
      </List>{' '}
      <StepButtons
        activeStep={activeStep}
        onBackClick={() => {
          setActiveStep(activeStep - 1);
        }}
        nextDisabled={!market}
        onNextClick={() => {
          setActiveStep(activeStep + 1);
        }}
      />
    </Box>
  );
};
export default DefaultMarket;

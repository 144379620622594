import { Box, ButtonBase, Paper } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { OpenInFull } from '@mui/icons-material';
import { ChartSeries } from '../useChartSeries';
import StockChart from './StockChart';
import { ChartsBlockTabType } from 'pages/Insights/InsightsComponents/types';
import Tabs from 'components/Tabs';
import TabPanel from 'components/TabPanel';

const StockChartsBlock = ({
  setFullScreen,
  tabs,
  activeTab,
  setActiveTab,
  layout,
  loading,
  error,
  chartSeries,
  marketTitle,
  pageTitle
}: {
  setFullScreen: Dispatch<SetStateAction<boolean>>;
  activeTab: number;
  setActiveTab: Dispatch<SetStateAction<number>>;
  tabs: ChartsBlockTabType[];
  layout?: { fullWidth?: boolean; height?: number };
  loading: boolean;
  error: boolean;
  chartSeries: ChartSeries[] | undefined;
  marketTitle: string;
  pageTitle: string;
}) => {
  const { fullWidth, height } = layout || {};
  return (
    <Paper
      sx={theme => ({
        width: fullWidth ? '100%' : 'calc(50% - 12px)',
        [theme.breakpoints.down(1350)]: {
          width: '100%'
        }
      })}
    >
      <Tabs
        mainPageTitle={marketTitle}
        subPageTitle={pageTitle}
        tabs={tabs.map(({ name }) => ({ name }))}
        activeTab={activeTab}
        height={48}
        offset={60}
        onChange={setActiveTab}
      />
      <TabPanel
        key={'name'}
        index={activeTab}
        value={activeTab}
      >
        <Box sx={{ mt: 1 }}>
          <Box
            className='chart-container'
            sx={{ position: 'relative ' }}
          >
            {!loading && (
              <Box sx={{ position: 'absolute', top: -25, right: 22, zIndex: 1 }}>
                <ButtonBase
                  onClick={() => {
                    setFullScreen(true);
                  }}
                >
                  <Box
                    sx={{
                      width: 30,
                      height: 24,
                      pl: 1,
                      pr: 1,
                      pt: '2px',
                      backgroundColor: '#121836'
                    }}
                  >
                    <OpenInFull sx={{ fontSize: 14 }} />
                  </Box>
                </ButtonBase>
              </Box>
            )}
            <StockChart
              loading={loading}
              error={error}
              chartSeries={chartSeries}
              height={height}
              parent={{
                mainPageTitle: marketTitle,
                subPageTitle: pageTitle,
                tabName: tabs?.at(activeTab)?.name
              }}
            />
          </Box>{' '}
        </Box>
      </TabPanel>
    </Paper>
  );
};

export default StockChartsBlock;

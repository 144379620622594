import { ReactNode } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const reCaptchaKey: string = import.meta.env.VITE_REACT_RE_CAPTCHA_KEY;

const OnboardingImageScreen = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        pt: '8%',
        pl: 4,
        flexDirection: 'row-reverse',
        width: '100%',
        height: '100vh',
        backgroundColor: 'background.default'
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <Box sx={{ overflow: 'hidden' }}>
          <img
            src={'/login-image.png'}
            alt='factsheetImage'
            style={{
              clipPath: 'inset(0 50% 0 0)',
              width: '200%',
              height: '100%',
              objectFit: 'contain'
            }}
          />
        </Box>
        <Box sx={{ width: '100%', pb: '8%' }}>
          <Divider
            variant='middle'
            sx={{
              '&::before': { borderTop: '1px solid #F8F8F8' },
              '&::after': { borderTop: '1px solid #F8F8F8' }
            }}
          >
            <Typography>Trusted By</Typography>
          </Divider>
          <Box sx={{ pt: 1, display: 'flex', justifyContent: 'space-around' }}>
            {' '}
            <img
              src={'/bloomberg-logo.png'}
              alt='bloomberg-logo'
            />
            <img
              src={'/exxon-logo.png'}
              alt='exxon-logo'
            />{' '}
            <img
              src={'/totalenergies-logo.png'}
              alt='totalenergies-logo'
            />
            <img
              src={'/woodmackenzie-logo.png'}
              alt='woodmackenzie-logo'
            />{' '}
            <img
              src={'/bp-logo.png'}
              alt='bp-logo'
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
const OnboardingLayout = ({ children }: { children: ReactNode }) => (
  <Box
    sx={{
      backgroundImage: 'linear-gradient(244deg, #121836 0%, #070818 99.27%) !important',
      width: 'fit-content',
      minWidth: '100%'
    }}
  >
    <Box
      sx={{
        minHeight: '100vh',
        height: '100%',
        minWidth: '300px',
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <GoogleReCaptchaProvider
        reCaptchaKey={reCaptchaKey}
        container={{
          element: 'onboarding-form-recaptcha',
          parameters: {
            badge: 'bottomleft',
            theme: 'dark'
          }
        }}
      >
        {children}
      </GoogleReCaptchaProvider>
      <OnboardingImageScreen />
    </Box>
  </Box>
);

export default OnboardingLayout;

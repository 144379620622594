import { useEffect, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import httpInstances from 'api/axiosInstance';
import { emailValidator } from 'utils/validators';

const useShareButton = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [content, setContent] = useState<string>('');
  const [recipient, setRecipient] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');

  useEffect(() => {
    setContent('');
    setEmailError('');
    setRecipient('');
  }, [modalOpen]);

  const sendEmail = async () => {
    const errorMessage = emailValidator(recipient);
    if (errorMessage) {
      setEmailError(errorMessage);
      return;
    }
    try {
      enqueueSnackbar('Email is sending, please wait...', {
        variant: 'loading'
      });
      setModalOpen(false);
      await httpInstances.axiosGoInstance.post(`/share`, {
        content: content,
        recipient,
        url: window.location.href
      });
      enqueueSnackbar('Email sent!', {
        variant: 'success',
        autoHideDuration: 5000
      });
    } catch {
      enqueueSnackbar('Something went wrong!', {
        variant: 'error',
        autoHideDuration: 5000
      });
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(window.location.href);
    enqueueSnackbar('Link copied to clipboard!', {
      variant: 'success',
      autoHideDuration: 5000
    });
  };

  const handleChangeRecipient = (value: string) => {
    setEmailError('');
    setRecipient(value);
  };

  return {
    modalOpen,
    setModalOpen,
    content,
    setContent,
    recipient,
    handleChangeRecipient,
    copyToClipboard,
    sendEmail,
    emailError
  };
};

export default useShareButton;

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { fetchLpData } from 'slices/launchpadSlice';
import { useAppDispatch, useAppSelector } from 'hooks/redux-hooks';
import useUserDetails from 'hooks/useUserAccess';
import { mockLaunchpadSymbols } from 'pages/Launchpad/constants';
import { selectUserConfig } from 'slices/userConfigSlice';

export interface LpDataItem {
  transactionTime: string;
  insertTime: string;
  symbol: {
    PeriodType: string;
    TimeRef: string;
    Period: string;
    Code: string;
  };
  values: {
    High?: string;
    Low?: string;
    PeriodStart?: string;
    PeriodEnd?: string;
    PeriodRel?: string;
    RecordStatus?: string;
    Mid?: string;
    Index?: string;
    PeriodAbs: string;
    FactsheetVersion: string;
  };
  metadata: {
    TimeZone: string;
    Alias: string;
    Currency: string;
    Frequency: string;
    HolidayCalendar: string;
    DeliveryBasis: string;
    Source: string;
    Units: string;
    Title: string;
    Commodity: string;
    PricingBasis: string;
    TradingHub: string;
  };
}

export type LpData = LpDataItem[];

const useLaunchpad = () => {
  const [activeTab, setActiveTab] = useState<number>(0);

  const { lpData } = useSelector((state: RootState) => state.launchpad);
  const { dataHubs, activeDataHubId } = useSelector((state: RootState) => state.dataHub);
  const { checkUserHasPremium } = useUserDetails();

  const launchpadSymbols = dataHubs.find(dataHub => dataHub.id === activeDataHubId)?.symbols || [];

  const dispatch = useAppDispatch();
  const isLaunchpadEmpty = !launchpadSymbols.length;

  const shownLaunchpadSymbols = checkUserHasPremium() ? launchpadSymbols : mockLaunchpadSymbols;

  const {
    userConfig: {
      timezone: { value }
    }
  } = useAppSelector(selectUserConfig);

  useEffect(() => {
    if (checkUserHasPremium())
      dispatch(fetchLpData({ symbols: launchpadSymbols, timezone: value }));
  }, [activeDataHubId]);

  const chartSeries = lpData.length
    ? shownLaunchpadSymbols.toReversed().map(key => ({
        settings: { type: 'line' as const },
        symbol: {
          key,
          group: 'Prod_Indexes'
        }
      }))
    : undefined;

  return {
    activeTab,
    setActiveTab,
    chartSeries,
    isLaunchpadEmpty
  };
};

export default useLaunchpad;

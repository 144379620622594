import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { SelectedSymbol } from './symbolSelectorSlice';
import httpInstances from 'api/axiosInstance';

const DATAHUB_APP_NAME = 'dataHub';

export type DataHub = {
  savedAt: string;
  symbols: SelectedSymbol[];
  name: string;
  id: string;
  exportSettings: {
    from: Date | null;
    to: Date | null;
    periodNumber: string;
    period: string;
    exportType: string;
    includeMetadata: boolean;
  };
};

type DataHubState = {
  status: 'idle' | 'loading' | 'failed' | 'fulfilled';
  error: string | null;
  dataHubs: DataHub[];
  activeDataHubId: string;
};

type ErrorResponse = {
  message: string;
};

const initialState: DataHubState = {
  status: 'idle',
  error: null,
  dataHubs: [],
  activeDataHubId: ''
};

export const getDataHubs = createAsyncThunk('getDataHub', async (_, { rejectWithValue }) => {
  try {
    const { data } = await httpInstances.axiosInternalInstance.get(`/config/` + DATAHUB_APP_NAME);
    return data;
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      const errorResponse = error.response.data;
      return rejectWithValue(errorResponse);
    }
    throw error;
  }
});

export const saveDataHub = createAsyncThunk(
  'saveDataHub',
  async (dataHub: DataHub, { rejectWithValue }) => {
    try {
      await httpInstances.axiosInternalInstance.post(
        `/config/${DATAHUB_APP_NAME}/` + dataHub.id,
        dataHub
      );
      return dataHub;
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;
        return rejectWithValue(errorResponse);
      }
      throw error;
    }
  }
);

export const saveDataHubs = createAsyncThunk(
  'saveDataHubs',
  async (dataHubs: DataHub[], { rejectWithValue }) => {
    try {
      const requestBody = dataHubs.reduce((key, value) => ({ ...key, [value.id]: value }), {});
      await httpInstances.axiosInternalInstance.post(`/config/${DATAHUB_APP_NAME}`, requestBody);
      return dataHubs;
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;
        return rejectWithValue(errorResponse);
      }
      throw error;
    }
  }
);

const dataHubSlice = createSlice({
  name: DATAHUB_APP_NAME,
  initialState,
  reducers: {
    setActiveDataHubId(state, action: PayloadAction<string>) {
      state.activeDataHubId = action.payload;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getDataHubs.pending, state => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(getDataHubs.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.dataHubs = Object.entries(action.payload).flatMap(
          dataHubArray => dataHubArray[1] as DataHub
        );
      })
      .addCase(getDataHubs.rejected, (state, action) => {
        state.status = 'failed';
        if (action.payload) {
          state.error = (action.payload as ErrorResponse).message || 'Request failed';
        } else {
          state.error = action.error.message || 'Request failed';
        }
      })
      .addCase(saveDataHubs.pending, state => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(saveDataHubs.fulfilled, (state, action: { type: string; payload: DataHub[] }) => {
        state.status = 'fulfilled';
        state.dataHubs = action.payload;
      })
      .addCase(saveDataHubs.rejected, (state, action) => {
        state.status = 'failed';
        if (action.payload) {
          state.error = (action.payload as ErrorResponse).message || 'Request failed';
        } else {
          state.error = action.error.message || 'Request failed';
        }
      })
      .addCase(saveDataHub.pending, state => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(saveDataHub.fulfilled, (state, action: { type: string; payload: DataHub }) => {
        state.status = 'fulfilled';
        const newDataPod = action.payload;

        let temp = [...state.dataHubs].filter(dataHub => dataHub.id !== newDataPod.id);
        temp = [newDataPod, ...temp];

        state.dataHubs = temp;
      })
      .addCase(saveDataHub.rejected, (state, action) => {
        state.status = 'failed';
        if (action.payload) {
          state.error = (action.payload as ErrorResponse).message || 'Request failed';
        } else {
          state.error = action.error.message || 'Request failed';
        }
      });
  }
});

export const { setActiveDataHubId } = dataHubSlice.actions;

export default dataHubSlice.reducer;

import { Box, IconButton, Typography } from '@mui/material';
import { UnauthenticatedMessage } from 'pages/Facthsheets/FactsheetView/FactsheetWindow/FactsheetBody';
import { FactsheetDetails, RelatedCode } from 'pages/Facthsheets/constants';

const RelatedCodeItem = ({
  relatedCode,
  openNewTab
}: {
  relatedCode: RelatedCode;
  openNewTab: (code: string) => void;
}) => {
  return (
    <Box sx={{ pb: 1, display: 'flex', alignItems: 'center' }}>
      <IconButton
        onClick={() => openNewTab(relatedCode.code)}
        sx={{ width: 28, height: 28, mr: 0.5 }}
      >
        <Typography sx={{ pb: '3px', fontWeight: 700 }}>◨</Typography>
      </IconButton>
      <Typography sx={{ fontWeight: 700, mr: 2 }}>{relatedCode.code}</Typography>
      <Typography>{relatedCode.title}</Typography>
    </Box>
  );
};

const RelatedCodesTab = ({
  factsheet,
  gxToken,
  openNewTab
}: {
  factsheet: FactsheetDetails;
  gxToken: string | null;
  openNewTab: (code: string) => void;
}) => {
  if (!gxToken) {
    return (
      <Box sx={{ pt: 5 }}>
        <UnauthenticatedMessage />
      </Box>
    );
  }
  return (
    <Box
      sx={{
        minWidth: 700
      }}
    >
      <Box sx={{ pt: 1 }}>
        <Typography sx={{ fontWeight: 700, pb: 1 }}>Parent Codes</Typography>
        {factsheet.parentCodes?.length === 0 && (
          <Typography sx={{ pb: 1 }}>Parent code is not available for this GX code.</Typography>
        )}
        {factsheet.parentCodes.map((parentCode, index) => (
          <RelatedCodeItem
            key={parentCode.code + index}
            relatedCode={parentCode}
            openNewTab={openNewTab}
          />
        ))}
      </Box>
      <Box>
        <Typography sx={{ fontWeight: 700, pb: 1 }}>Child Codes</Typography>
        {factsheet.childCodes?.length === 0 && (
          <Typography sx={{ pb: 1 }}>Child code is not available for this GX code.</Typography>
        )}
        {factsheet.childCodes.map((childCode, index) => (
          <RelatedCodeItem
            key={childCode.code + index}
            relatedCode={childCode}
            openNewTab={openNewTab}
          />
        ))}
      </Box>
    </Box>
  );
};
export default RelatedCodesTab;

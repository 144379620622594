import { Box, TableCell, TableRow } from '@mui/material';

import useSelectionFunctions from 'components/SymbolSelector/useSelectionFunctions';
import { SelectedSymbol } from 'slices/symbolSelectorSlice';
import CustomCheckbox from 'components/Checkbox';
import { FactsheetSymbol } from 'pages/Facthsheets/constants';

const ItemsTableRow = ({ symbol, code }: { symbol: FactsheetSymbol; code: string }) => {
  const { isSymbolSelected, toggleSymbolInSelection } = useSelectionFunctions();
  const { timeRef, period, periodType, alias, timeRefDetail } = symbol;
  const selectedSymbol: SelectedSymbol = {
    Code: code,
    Period: period,
    PeriodType: periodType,
    TimeRef: timeRef
  };
  const itemKey = code + timeRef + period + periodType;

  return (
    <TableRow
      key={itemKey}
      sx={{
        '&:last-child td, &:last-child th': { pb: 1 },
        '&:first-of-type td, &:first-of-type th': { pt: 1 }
      }}
    >
      <TableCell
        sx={{
          p: 0,
          pl: 1,
          pr: 1,
          borderLeft: 'none'
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <CustomCheckbox
            checked={isSymbolSelected(selectedSymbol)}
            onChange={() => {
              toggleSymbolInSelection(selectedSymbol, isSymbolSelected(selectedSymbol));
            }}
          />
        </Box>
      </TableCell>
      <TableCell
        component='th'
        scope='row'
        sx={{
          display: 'flex',
          alignItems: 'center'
        }}
      >
        {code}
      </TableCell>
      <TableCell>{timeRef}</TableCell>
      <TableCell>{periodType}</TableCell>
      <TableCell>{period}</TableCell>
      <TableCell sx={{ width: '20%' }}>{alias}</TableCell>
      <TableCell sx={{ width: '20%' }}>{timeRefDetail}</TableCell>
    </TableRow>
  );
};
export default ItemsTableRow;

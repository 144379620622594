import { Box, ButtonBase, Link, Typography } from '@mui/material';

import { ChevronLeft } from '@mui/icons-material';
import GoLogo from 'components/GoLogo';
import OnboardingLayout from 'layouts/OnboardingLayout';
import LoginComponent from 'pages/Onboarding/LoginComponent';
import useOnboarding from 'pages/Onboarding/useOnboarding';
import useLoginComponent from 'pages/Onboarding/LoginComponent/useLoginComponent';

const LoginHeader = () => {
  return (
    <>
      <Typography sx={{ mb: 3, fontSize: 32, fontWeight: 700 }}>Welcome to GX Go!</Typography>
      <Typography sx={{ mb: 1.5, fontSize: 24, fontWeight: 700 }}>
        Please enter your email address
      </Typography>
    </>
  );
};
const LoginFooter = () => {
  return (
    <>
      {' '}
      <Typography sx={{ fontWeight: 500 }}>
        Not registered yet?{' '}
        <Link
          href='/onboarding'
          rel='noopener noreferrer'
          color='primary.main'
          sx={{ fontWeight: 500, fontSize: 16, pl: 0.5, textDecoration: 'none' }}
        >
          {' '}
          Sign up here
        </Link>
      </Typography>
    </>
  );
};
const Login = () => {
  const { logoExpanded, resetPage, status } = useOnboarding();
  const { isLoggedIn } = useLoginComponent();

  return (
    <OnboardingLayout>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          mt: '8%',
          ml: 4
        }}
      >
        {' '}
        <Box>
          <GoLogo open={logoExpanded} />
          {!isLoggedIn && !['idle', 'loading', 'onSuppressionList'].includes(status) && (
            <ButtonBase
              onClick={resetPage}
              sx={{ mt: 3 }}
            >
              <ChevronLeft fontSize='small' />
              <Typography
                variant='subtitle1'
                sx={{ fontSize: 16 }}
              >
                Back
              </Typography>
            </ButtonBase>
          )}
          <Box sx={{ maxWidth: '550px', mt: 3 }}>
            <LoginComponent
              loginHeader={<LoginHeader />}
              loginFooter={<LoginFooter />}
            />
          </Box>
        </Box>
      </Box>
    </OnboardingLayout>
  );
};

export default Login;

import { Box, Typography } from '@mui/material';
import useUserDetails from 'hooks/useUserAccess';
import UpgradeToPremiumButton from 'pages/Launchpad/UpgradeToPremiumButton';

const UpgradeToPremiumScreen = ({ noButton }: { noButton?: boolean }) => {
  const { checkUserHasPremium } = useUserDetails();
  if (checkUserHasPremium()) return null;

  return (
    <>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backdropFilter: 'blur(6px)',
          zIndex: 1400
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          top: 'calc(20%)',
          left: 'calc(50% - 300px)',
          textAlign: 'center',
          zIndex: 1401,
          width: 600
        }}
      >
        <Typography sx={{ fontSize: 20, mb: 2 }}>This is a Premium feature.</Typography>
        <Typography sx={{ fontSize: 20, mb: 2 }}>
          Upgrade for advanced functionality and exports.
        </Typography>
        {!noButton && <UpgradeToPremiumButton />}
      </Box>
    </>
  );
};

export default UpgradeToPremiumScreen;

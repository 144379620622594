import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Button, Paper } from '@mui/material';
import { Add, Bookmark, DeleteOutlined, ExpandMore, FileDownload } from '@mui/icons-material';
import LaunchpadsDropdown from '../LaunchpadsDropdown';
import { RootState } from 'store';
import { useAppDispatch } from 'hooks/redux-hooks';
import { setSymbols } from 'slices/symbolSelectorSlice';
import Dialog from 'components/Dialog';
import { SPOT_CATALOG_PATH } from 'utils/constants';
import useDataHubSelector from 'pages/Launchpad/useDataHubSelector';
import useUserDetails from 'hooks/useUserAccess';

const LaunchpadActionBar = ({
  activeTab,
  setActiveTab
}: {
  activeTab: number;
  setActiveTab: (index: number) => void;
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { checkUserHasPremium } = useUserDetails();

  const isPremiumUser = checkUserHasPremium();
  const { dataHubs, activeDataHubId } = useSelector((state: RootState) => state.dataHub);
  const { name = '', symbols = [] } =
    dataHubs.find(dataHub => dataHub.id === activeDataHubId) || {};

  const { deleteDataHub } = useDataHubSelector();
  const [confirmClearDialogOpen, setConfirmClearDialogOpen] = useState(false);
  const dropdownButtonContent = (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: 158
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Bookmark sx={{ fontSize: 16, mr: 1 }} />
        All Data Hubs
      </Box>
      <ExpandMore sx={{ mr: '0!important' }} />
    </Box>
  );
  return (
    <>
      <Paper sx={{ mt: 3, px: 2, py: 1, display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', flexWrap: 'nowrap' }}>
          <LaunchpadsDropdown
            setActiveTab={setActiveTab}
            buttonVariant='outlined'
            buttonContent={dropdownButtonContent}
          />
          <Button
            disabled={!activeDataHubId || !isPremiumUser}
            variant='outlined'
            sx={{ ml: 2, width: 188 }}
            onClick={() => setConfirmClearDialogOpen(true)}
          >
            <DeleteOutlined />
            Delete this Data Hub
          </Button>
        </Box>
        <Box sx={{ display: 'flex', flexWrap: 'nowrap' }}>
          {[0].includes(activeTab) && (
            <Button
              disabled={!symbols.length || !isPremiumUser}
              onClick={() => dispatch(setSymbols(symbols))}
              variant='outlined'
              sx={{ width: 188, mr: 2 }}
            >
              <Box sx={{ width: 14, height: 14, backgroundColor: 'primary.main', mr: 0.5 }}></Box>
              Back to selector
            </Button>
          )}
          {[1, 2].includes(activeTab) && (
            <Button
              disabled={!symbols.length || !isPremiumUser}
              variant='outlined'
              sx={{ width: 188, mr: 2 }}
              onClick={() => setActiveTab(3)}
            >
              <FileDownload />
              Export data
            </Button>
          )}
          <Button
            variant='outlined'
            disabled={!isPremiumUser}
            sx={{ width: 188 }}
            onClick={() => navigate('/catalog/' + SPOT_CATALOG_PATH)}
          >
            <Add />
            Select more
          </Button>
        </Box>
      </Paper>
      <Dialog
        text={
          <>
            Delete '{name}' Data Hub?
            <br />
            <br />
            You will permanently lose this saved Data Hub.
          </>
        }
        actionButtonText='Delete Data Hub'
        hasCancelButton
        open={confirmClearDialogOpen}
        handleClose={() => setConfirmClearDialogOpen(false)}
        handleAction={() => {
          deleteDataHub(activeDataHubId);
          setActiveTab(0);
          setConfirmClearDialogOpen(false);
        }}
      />
    </>
  );
};

export default LaunchpadActionBar;

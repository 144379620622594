import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { FactsheetDetails } from 'pages/Facthsheets/constants';
import { UnauthenticatedMessage } from 'pages/Facthsheets/FactsheetView/FactsheetWindow/FactsheetBody';
import Checkbox from 'components/Checkbox';
import useItemsTab from 'pages/Facthsheets/FactsheetView/FactsheetWindow/FactsheetBody/Tabs/ItemsTab/useItemsTab';
import ItemsTableRow from 'pages/Facthsheets/FactsheetView/FactsheetWindow/FactsheetBody/Tabs/ItemsTab/ItemsTableRow';

const ItemsTab = ({
  factsheet,
  gxToken
}: {
  factsheet: FactsheetDetails;
  gxToken: string | null;
}) => {
  const { handleAllItemsSelection, allItemsSelected } = useItemsTab(factsheet);
  if (!gxToken) {
    return (
      <Box sx={{ pt: 5 }}>
        <UnauthenticatedMessage />
      </Box>
    );
  }
  if (factsheet.symbols?.length > 0) {
    return (
      <Box sx={{ width: '100%' }}>
        <Typography sx={{ fontWeight: 'bold' }}>
          Item(s) Available {factsheet.symbols?.length}
        </Typography>
        <TableContainer>
          <Table
            aria-label='factsheet items table'
            size='small'
            stickyHeader
          >
            <TableHead>
              <TableRow
                sx={theme => ({
                  '& td, & th': {
                    pb: 1,
                    fontWeight: 'bold',
                    borderBottom: `0.5px solid ${theme.palette.text.primary}`,
                    backgroundColor: 'background.paper'
                  }
                })}
              >
                <TableCell
                  component='th'
                  scope='row'
                  align='right'
                  sx={{ pl: 1, pr: 1, width: 24 }}
                >
                  <Checkbox
                    checked={allItemsSelected()}
                    onChange={handleAllItemsSelection}
                    sx={{ mb: '3px' }}
                    inputProps={{
                      'aria-label': 'select all'
                    }}
                  />
                </TableCell>

                <TableCell>Code</TableCell>
                <TableCell>TimeRef</TableCell>
                <TableCell>PeriodType</TableCell>
                <TableCell>Period</TableCell>
                <TableCell>Alias</TableCell>
                <TableCell> TimeRef Detail</TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                '&  .markets-table-row:nth-of-type(even)': {
                  backgroundColor: 'secondary.main'
                },
                '&  .markets-table-row:nth-of-type(even) + td': {
                  backgroundColor: 'secondary.main'
                }
              }}
            >
              {factsheet.symbols.map((symbol, index) => (
                <ItemsTableRow
                  key={factsheet.code + index}
                  symbol={symbol}
                  code={factsheet.code}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  }
};
export default ItemsTab;

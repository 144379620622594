import { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import useTokenGeneration from 'pages/Profile/TokenGeneration/useTokenGeneration';
import { RootState } from 'store';
import httpInstances from 'api/axiosInstance';
import { transformPickerDateToISO } from 'utils/commonFunctions';
import { saveDataHub } from 'slices/dataHubSlice';
import { useAppDispatch } from 'hooks/redux-hooks';

let timerId: any = null;

export interface ExportSettings {
  from: Date | null;
  to: Date | null;
  periodNumber: string;
  period: string;
  exportType: string;
  includeMetadata: boolean;
}

const defaultExportSettings: ExportSettings = {
  from: null,
  to: null,
  periodNumber: '2',
  period: 'm',
  exportType: 'all',
  includeMetadata: false
};

const useLaunchpadExport = () => {
  const dispatch = useAppDispatch();
  const { apiKeys } = useTokenGeneration();

  const { dataHubs, activeDataHubId } = useSelector((state: RootState) => state.dataHub);
  const activeDataHub = dataHubs.find(dataHub => dataHub.id === activeDataHubId);

  const { userConfig } = useSelector((state: RootState) => state.userConfig);
  const userTimezone = userConfig.timezone.value;

  const [exportSettings, setExportSettings] = useState<ExportSettings>({
    ...defaultExportSettings,
    ...(activeDataHub?.exportSettings || {})
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [exported, setExported] = useState<boolean>(false);
  const [previewOptionId, setPreviewOptionId] = useState<string>('');

  useEffect(() => {
    if (activeDataHub) {
      setExportSettings({
        ...defaultExportSettings,
        ...activeDataHub.exportSettings
      });
    }
  }, [activeDataHub]);

  useEffect(() => {
    clearTimeout(timerId);
  }, [previewOptionId]);

  const updateExportSetting = useCallback(
    (key: keyof typeof exportSettings, value: any) => {
      setExportSettings(prev => {
        const updatedSettings = { ...prev, [key]: value };
        if (activeDataHub) {
          dispatch(
            saveDataHub({
              ...activeDataHub,
              exportSettings: updatedSettings
            })
          );
        }
        return updatedSettings;
      });
    },
    [dispatch, activeDataHub]
  );

  const gxURL = import.meta.env.VITE_GX_API_ENDPOINT;

  const getExportBody = () => ({
    symbols:
      activeDataHub?.symbols.map(symbol => ({
        group: 'Prod_Indexes',
        key: {
          code: symbol.Code,
          periodType: symbol.PeriodType,
          timeRef: symbol.TimeRef,
          period: symbol.Period
        }
      })) || []
  });

  const getFromToValues = () => {
    let fromValue = '';
    let toValue = '';

    switch (exportSettings.exportType) {
      case 'all':
        fromValue = 'all';
        break;
      case 'range':
        fromValue = `${exportSettings.periodNumber}${exportSettings.period}`;
        break;
      case 'dates':
        fromValue = transformPickerDateToISO(exportSettings.from, 'start');
        toValue = transformPickerDateToISO(exportSettings.to, 'end');
        break;
      default:
        fromValue = 'all';
    }

    return { fromValue, toValue };
  };

  const { fromValue, toValue } = getFromToValues();

  const exportData = async (type: string) => {
    setLoading(true);
    try {
      const endpoint = `export/${type.startsWith('excel') ? type : `third-party?type=${type}`}&from=${fromValue}${toValue ? `&to=${toValue}` : ''}&metadata=${exportSettings.includeMetadata}`;
      await httpInstances.axiosGoInstance.post(endpoint, getExportBody());
      setExported(true);
      if (!type.startsWith('excel')) {
        timerId = setTimeout(() => {
          setExported(false);
          setPreviewOptionId('');
        }, 5000);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const uniqueSymbolCodes = activeDataHub?.symbols.map(symbol => symbol.Code).join(',') || '';
  const sortedKeys = [...apiKeys]?.sort(
    (a, b) => new Date(a.creationDate).getTime() - new Date(b.creationDate).getTime()
  );
  const apiKey = sortedKeys?.slice(-1)[0]?.prefixedRawApiKey ?? '<YourApiKeyHere>';
  const exportApiRequestString = `${gxURL}/index?code=${uniqueSymbolCodes}&from=${fromValue}${toValue ? `&to=${toValue}` : ''}&metadata=${exportSettings.includeMetadata}&timezone=${userTimezone}&token=${apiKey}`;
  const exportCurlRequestString = `curl --location "${gxURL}/index?code=${uniqueSymbolCodes}&from=${fromValue}${toValue ? `&to=${toValue}` : ''}&timezone=${userTimezone}&metadata=${exportSettings.includeMetadata}" --header "Accept: application/json" --header "Authorization: ApiKey ${apiKey}"`;

  return {
    loading,
    exported,
    setExported,
    exportData,
    exportApiRequestString,
    exportCurlRequestString,
    exportSettings,
    updateExportSetting,
    previewOptionId,
    setPreviewOptionId
  };
};

export default useLaunchpadExport;

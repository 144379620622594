import { ReactNode } from 'react';
import { Box, Container } from '@mui/material';
import { useSelector } from 'react-redux';
import NavigationMenu from './NavigationMenu';
import SearchAndStreamBar from 'components/SearchAndStreamBar';
import TermsDialog from 'components/TermsDialog';
import { RootState } from 'store';

const MainLayout = ({ children }: { children: ReactNode }) => {
  const authState = useSelector((state: RootState) => state.auth);
  return (
    <Container
      sx={theme => ({
        display: 'flex',
        height: '100%',
        width: '100%',
        pr: '0!important',
        pl: '0!important',
        [theme.breakpoints.up('xl')]: {
          borderLeft: '1px solid #121836'
        }
      })}
      maxWidth='xl'
      className='visible-scrollbar'
    >
      <TermsDialog open={authState.status === 'fulfilled' && !authState.terms} />
      <NavigationMenu />
      <Box
        sx={{
          flexGrow: 1,
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100vh',
          overflowY: 'auto'
        }}
      >
        <SearchAndStreamBar />
        {children}
      </Box>
    </Container>
  );
};

export default MainLayout;

import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import Tabs from 'components/Tabs';
import DetailsTab from 'pages/Facthsheets/FactsheetView/FactsheetWindow/FactsheetBody/Tabs/DetailsTab';
import ApproachTab from 'pages/Facthsheets/FactsheetView/FactsheetWindow/FactsheetBody/Tabs/ApproachTab';
import RelatedCodesTab from 'pages/Facthsheets/FactsheetView/FactsheetWindow/FactsheetBody/Tabs/RelatedCodesTab';
import FlowchartTab from 'pages/Facthsheets/FactsheetView/FactsheetWindow/FactsheetBody/Tabs/FlowchartTab';
import HistoryTab from 'pages/Facthsheets/FactsheetView/FactsheetWindow/FactsheetBody/Tabs/HistoryTab';
import CriteriaTab from 'pages/Facthsheets/FactsheetView/FactsheetWindow/FactsheetBody/Tabs/CriteriaTab';
import { getGxTokenFromStorage } from 'utils/commonFunctions';
import ItemsTab from 'pages/Facthsheets/FactsheetView/FactsheetWindow/FactsheetBody/Tabs/ItemsTab';
import { FactsheetDetails } from 'pages/Facthsheets/constants';
import TabPanel from 'components/TabPanel';

export const UnauthenticatedMessage = () => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
      <Typography
        variant='h5'
        sx={{ fontWeight: 600, fontSize: 24 }}
      >
        This is a user-only feature.{' '}
      </Typography>
      <Typography
        variant='h5'
        sx={{ fontWeight: 600, fontSize: 24 }}
      >
        Sign in or register for GX Go to enable this and other advanced functionality.
      </Typography>
    </Box>
  );
};

const QualitySpecTab = ({
  factsheet,
  gxToken
}: {
  factsheet: FactsheetDetails;
  gxToken: string | null;
}) => {
  return gxToken ? (
    <Typography
      sx={{
        fontSize: 16,
        fontWeight: 500
      }}
    >
      {factsheet.factsheetVersionHistory.length > 0 &&
        factsheet.factsheetVersionHistory[0].qualitySpecification}
    </Typography>
  ) : (
    <Box sx={{ pt: 5 }}>
      <UnauthenticatedMessage />
    </Box>
  );
};

const FactsheetBody = ({
  factsheet,
  openNewTab
}: {
  factsheet: FactsheetDetails;
  openNewTab: (code: string) => void;
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const gxToken = getGxTokenFromStorage();
  const contentTabs = [
    { position: 0, name: 'Details', component: <DetailsTab factsheet={factsheet} /> },
    {
      position: 1,
      name: 'History',
      component: <HistoryTab factsheet={factsheet} />
    },
    {
      position: 4,
      name: 'Approach',
      component: (
        <ApproachTab
          factsheet={factsheet}
          gxToken={gxToken}
        />
      )
    },
    {
      position: 98,
      name: 'Available items',
      component: (
        <ItemsTab
          factsheet={factsheet}
          gxToken={gxToken}
        />
      )
    },
    {
      position: 99,
      name: 'Related codes',
      component: (
        <RelatedCodesTab
          factsheet={factsheet}
          gxToken={gxToken}
          openNewTab={openNewTab}
        />
      )
    }
  ];
  if (
    factsheet.factsheetVersionHistory.length > 0 &&
    factsheet.factsheetVersionHistory[0].qualitySpecification
  )
    contentTabs.unshift({
      position: 2,
      name: 'Quality spec',
      component: (
        <QualitySpecTab
          factsheet={factsheet}
          gxToken={gxToken}
        />
      )
    });
  if (
    factsheet.factsheetVersionHistory.length > 0 &&
    factsheet.factsheetVersionHistory[0].criteriaForInclusion
  )
    contentTabs.unshift({
      position: 3,
      name: 'Criteria',
      component: (
        <CriteriaTab
          factsheet={factsheet}
          gxToken={gxToken}
        />
      )
    });

  if (
    factsheet.factsheetVersionHistory.length > 0 &&
    factsheet.factsheetVersionHistory[0].flowcharts?.find(flowchartId => flowchartId !== 'false')
  )
    contentTabs.unshift({
      position: 5,
      name: 'Flowchart',
      component: (
        <FlowchartTab
          factsheet={factsheet}
          gxToken={gxToken}
        />
      )
    });

  return (
    <Box
      sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', maxWidth: 1000 }}
    >
      <Box
        sx={{
          my: 3,
          display: 'flex',
          justifyContent: 'space-between',
          maxWidth: 1000,
          width: 'calc(100vw - 150px)',
          minWidth: 700
        }}
      >
        <Tabs
          mainPageTitle={factsheet.code}
          tabs={contentTabs.sort((a, b) => a.position - b.position).map(({ name }) => ({ name }))}
          activeTab={activeTab}
          onChange={setActiveTab}
        />
      </Box>
      {contentTabs.map((tab, index) => (
        <TabPanel
          sx={{
            maxWidth: 1000,
            width: 'calc(100vw - 50px)',
            minWidth: 700,
            height: '40vh',
            maxHeight: 490,
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
              width: '8px'
            },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: '12px',
              backgroundColor: '#6e729c'
            }
          }}
          key={factsheet.code + index}
          index={index}
          value={activeTab}
        >
          {tab.component}
        </TabPanel>
      ))}
    </Box>
  );
};
export default FactsheetBody;

import { useEffect, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import httpInstances from 'api/axiosInstance';
import { transformPickerDateToISO } from 'utils/commonFunctions';

const useJsonCsvPreview = (
  exportType: 'all' | 'range' | 'dates',
  includeMetadata: boolean,
  exportCurlRequestString: string,
  capturePosthogEvent: () => void,
  from?: Date | null,
  to?: Date | null,
  period?: string,
  periodNumber?: string,
  isCSV?: boolean
) => {
  const { dataHubs, activeDataHubId } = useSelector((state: RootState) => state.dataHub);
  const launchpadSymbols = dataHubs.find(dataHub => dataHub.id === activeDataHubId)?.symbols || [];
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [fileContent, setFileContent] = useState<string>('');

  useEffect(() => {
    getLaunchpadIndexes();
  }, [launchpadSymbols, from, to, includeMetadata, period, periodNumber, exportType, isCSV]);

  const getLaunchpadIndexes = async () => {
    setLoading(true);
    setError(false);

    try {
      const fromValue =
        exportType === 'range'
          ? `${periodNumber}${period}`
          : exportType === 'dates' && from
            ? transformPickerDateToISO(from, 'start')
            : 'all';

      const toValue = exportType === 'dates' && to ? transformPickerDateToISO(to, 'end') : '';

      const headers = isCSV ? { Accept: 'text/csv' } : undefined;

      const requests = launchpadSymbols.map(({ Code, Period, PeriodType, TimeRef }) => {
        if (!Code) return null;

        return httpInstances.axiosDataInstance.get(
          `/index?code=${Code}&period=${Period}&periodType=${PeriodType}&timeRef=${TimeRef}&from=${fromValue}${
            toValue ? `&to=${toValue}` : ''
          }&metadata=${includeMetadata}`,
          { headers }
        );
      });

      const results = await Promise.all(requests.filter(Boolean));

      if (isCSV) {
        const csvData = combineCsvData(results.map(response => response?.data));
        setFileContent(csvData);
      } else {
        const flattenedData = results.flatMap(response => response?.data);
        setFileContent(JSON.stringify(flattenedData, null, 2));
      }
    } catch (error) {
      console.error(error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const combineCsvData = (responses: string[]): string => {
    if (!responses || responses.length === 0) return '';

    const [firstResponse, ...otherResponses] = responses;
    const [header, ...rows] = firstResponse.split('\n').filter(line => line.trim() !== '');
    const combinedRows = [...rows];

    for (const response of otherResponses) {
      const [, ...dataRows] = response.split('\n');
      combinedRows.push(...dataRows.filter(row => row.trim() !== ''));
    }

    return [header, ...combinedRows].join('\n');
  };

  const downloadFile = () => {
    const now = new Date();
    const padZero = (num: number) => num.toString().padStart(2, '0');

    const fileName = `GX-Data_${now.getFullYear().toString().slice(-2)}${padZero(now.getMonth() + 1)}${padZero(now.getDate())}_${padZero(now.getHours())}${padZero(now.getMinutes())}${padZero(now.getSeconds())}.`;

    const blob = new Blob([fileContent], {
      type: isCSV ? 'text/csv;charset=utf-8;' : 'application/json;charset=utf-8;'
    });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', `${fileName}${isCSV ? 'csv' : 'json'}`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    capturePosthogEvent();
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(isCSV ? fileContent : exportCurlRequestString);
    enqueueSnackbar('Code snippet copied to clipboard!', {
      variant: 'success',
      autoHideDuration: 5000
    });
    capturePosthogEvent();
  };

  return { copyToClipboard, downloadFile, loading, error, fileContent };
};

export default useJsonCsvPreview;

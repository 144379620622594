import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useAppDispatch } from 'hooks/redux-hooks';
import { resetStatus } from 'slices/authSlice';
import { getGxTokenFromStorage } from 'utils/commonFunctions';

const useOnboarding = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [logoExpanded, setLogoExpanded] = useState<boolean>(false);
  const { tripToken, status } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    resetPage();
    handleEntrance();
    return () => resetPage();
  }, [tripToken]);

  const handleEntrance = () => {
    setLogoExpanded(true);
    const gxToken = getGxTokenFromStorage();
    if (gxToken) navigate('/markets');
  };

  const resetPage = () => {
    dispatch(resetStatus());
  };

  return {
    logoExpanded,
    resetPage,
    status
  };
};

export default useOnboarding;

import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';

export type SubMenuItem = {
  name: string;
  onClick: (link: string) => void;
  icon?: ReactElement;
  link: string;
};
const NavBarSubMenu = ({ subMenuItems }: { subMenuItems: SubMenuItem[] }) => {
  const location = useLocation();
  return (
    <List
      sx={{
        backgroundColor: 'background.paper',
        borderBottom: '2px solid',
        borderBottomColor: 'primary.main',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      {subMenuItems.map(({ name, icon, onClick, link }) => (
        <ListItem
          key={name}
          disablePadding
          sx={{ display: 'block', '&:last-child': { mt: 'auto' } }}
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              px: 3,
              justifyContent: 'center'
            }}
            selected={link === location.pathname}
            onClick={() => {
              onClick(link);
            }}
          >
            <ListItemIcon
              sx={{
                fontSize: '14px',
                minWidth: 0,
                justifyContent: 'center',
                mr: 3,
                ml: 0.5
              }}
            >
              {icon}
            </ListItemIcon>
            <ListItemText primary={name} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};

export default NavBarSubMenu;

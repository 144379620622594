import { useEffect, useState } from 'react';
import { Box, Divider, Paper, Typography } from '@mui/material';
import httpInstances from 'api/axiosInstance';
import { FactsheetDetails } from 'pages/Facthsheets/constants';

const MapImage = ({ location, zoom }: { location: string; zoom: string }) => {
  const [imageSrc, setImageSrc] = useState('');
  const [error, setError] = useState('');
  useEffect(() => {
    if (location) {
      const locationUri = `/factsheets/config/location?location=${encodeURI(location.replace(' ', ','))}&zoom=${zoom}&dark=true`;
      httpInstances.axiosGxInstance
        .get(locationUri, {
          responseType: 'blob'
        })
        .then(response => {
          if (response.status === 200) {
            const imgUrl = URL.createObjectURL(response.data);
            setImageSrc(imgUrl);
          } else setError('Unable to get map image');
        })
        .catch(error => {
          console.error('Error fetching image:', error);
          setError('Error fetching image:' + error);
        });
    }
  }, [location]);

  if (!error) {
    return (
      <Box>
        {imageSrc ? (
          <img
            src={imageSrc}
            alt='factsheetImage'
            style={{ width: '450px', height: '400px' }}
          />
        ) : (
          <p>Loading...</p>
        )}
      </Box>
    );
  }
};

const Tile = ({
  label,
  value
}: {
  label: string;
  value: string | boolean | string[] | undefined;
}) => {
  if (value)
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start',
          p: 1.5,
          overflow: 'hidden',
          minWidth: 180
        }}
      >
        <Typography sx={{ fontWeight: 700 }}>{label}</Typography>
        {Array.isArray(value) ? (
          value.map((item, index) => <Typography key={item + index}>{item}</Typography>)
        ) : (
          <Typography>{value}</Typography>
        )}
      </Box>
    );
};

const DetailsTab = ({ factsheet }: { factsheet: FactsheetDetails }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
      <Box>
        <Paper
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'flex-start'
          }}
        >
          <Tile
            label={'Currency / Units'}
            value={`${factsheet.indexDetails['currency']} / ${factsheet.indexDetails['units']}`}
          />
          <Tile
            label={'Frequency'}
            value={factsheet.indexDetails['frequency']}
          />
          <Tile
            label={'Pricing Basis'}
            value={factsheet.indexDetails['pricingBasis']}
          />
          <Tile
            label={'Timezone'}
            value={factsheet.indexDetails['timezone']}
          />
          <Tile
            label={'Trading Hub'}
            value={factsheet.indexDetails['tradingHub']}
          />
          <Tile
            label={'Delivery Basis'}
            value={factsheet.indexDetails['deliveryBasis']}
          />
          <Tile
            label={'Commodity'}
            value={factsheet.indexDetails['commodity']}
          />
          <Tile
            label={'Index Module(s)'}
            value={factsheet.indexDetails['indexModules']}
          />
          <Tile
            label={'Holiday Calendar'}
            value={factsheet.indexDetails['holidayCalendar']}
          />
          <Tile
            label={'Last Trade Calendar'}
            value={factsheet.indexDetails['lastTradeCalendar']}
          />
          <Tile
            label={'Columns Available'}
            value={factsheet.indexDetails['columnsAvailable']}
          />
          <Tile
            label={'Increment'}
            value={factsheet.indexDetails['increment']}
          />
        </Paper>
        <Divider sx={{ backgroundColor: 'secondary.main', pr: 4 }} />
        <Paper
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'flex-start'
          }}
        >
          <Tile
            label={'Status'}
            value={factsheet.indexDetails['status']}
          />
          <Tile
            label={'Start Date'}
            value={factsheet.indexDetails['startDate']}
          />
          <Tile
            label={'End Date'}
            value={factsheet.indexDetails['endDate']}
          />
        </Paper>
        <Divider sx={{ backgroundColor: 'secondary.main', pr: 4 }} />
        <Paper
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'flex-start'
          }}
        >
          <Tile
            label={'Spot Alias'}
            value={factsheet.indexDetails['spotAlias']}
          />
          <Tile
            label={'Forward Curve'}
            value={factsheet.indexDetails['forwardCurve'] ? 'Yes' : 'No'}
          />
          <Tile
            label={'Num Items'}
            value={factsheet.symbols?.length.toString()}
          />
        </Paper>
      </Box>
      {factsheet.mapLocation && (
        <MapImage
          location={factsheet.mapLocation}
          zoom={'10'}
        />
      )}
    </Box>
  );
};
export default DetailsTab;

import { DraggableEvent } from 'react-draggable';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { RootState } from 'store';
import { useAppDispatch } from 'hooks/redux-hooks';
import { dismissLimit } from 'slices/symbolSelectorSlice';

const useSymbolSelector = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const checkIfInCatalog = () => location.pathname.toLowerCase().includes('/catalog');
  const symbolSelectorState = useSelector((state: RootState) => state.symbolSelector);

  const [position, setPosition] = useState<{ x: number; y: number }>();

  useEffect(() => {
    if (symbolSelectorState.symbols.length > 0) {
      if (!position) {
        setPosition(getDefaultPosition());
      }
    }
  }, [symbolSelectorState.symbols.length]);

  useEffect(() => {
    const handleResize = () => {
      setPosition(prevPosition => {
        if (prevPosition) {
          const viewportWidth = window.innerWidth;
          const viewportHeight = window.innerHeight;
          const elementWidth = 718;
          const elementHeight = 50;

          let { x, y } = prevPosition;

          if (x + elementWidth > viewportWidth) x = viewportWidth - elementWidth;
          if (y + elementHeight > viewportHeight) y = viewportHeight - elementHeight;
          if (x < 0) x = 0;
          if (y < 0) y = 0;

          return { x, y };
        }
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [checkIfInCatalog]);

  const getDefaultPosition = () => {
    return {
      x: window.innerWidth / 2 - 358,
      y: checkIfInCatalog() ? window.innerHeight - 130 : 180
    };
  };

  const handleClose = () => {
    dispatch(dismissLimit());
  };

  const handleDrag = (_: DraggableEvent, data: { x: number; y: number }) => {
    setPosition({ x: data.x, y: data.y });
  };

  return {
    handleDrag,
    handleClose,
    symbolSelectorState,
    position,
    dispatch,
    navigate,
    checkIfInCatalog,
    setPosition
  };
};
export default useSymbolSelector;

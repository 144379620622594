import { enqueueSnackbar, SnackbarKey } from 'notistack';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks/redux-hooks';
import { DataHub, saveDataHub, saveDataHubs, setActiveDataHubId } from 'slices/dataHubSlice';
import { clearLaunchpad, deleteSymbolFromLaunchpad, fetchLpData } from 'slices/launchpadSlice';
import { clearSelection, SelectedSymbol, setLimitReached } from 'slices/symbolSelectorSlice';
import { RootState } from 'store';
import { MAX_SELECTED_SYMBOLS } from 'utils/constants';
import { generateUUID } from 'utils/commonFunctions';
import { selectUserConfig } from 'slices/userConfigSlice';
import { ExportSettings } from 'pages/Launchpad/Export/useLaunchpadExport';
import useDateText, { DateTextVariants } from 'components/DateText/useDateText';

const callErrorSnackbar = () => {
  return enqueueSnackbar('Oops! We couldn’t save your dataHub. Please try again.', {
    variant: 'error',
    persist: true
  });
};

const callLoadingSnackbar = () => {
  return enqueueSnackbar('Items are adding, please wait...', {
    variant: 'loading',
    persist: true
  });
};

const callSuccessSnackbar = (difference: number) => {
  return enqueueSnackbar(`${difference} ${difference === 1 ? 'item' : 'items'} added!`, {
    variant: 'success',
    autoHideDuration: 5000
  });
};

const useDataHubSelector = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const symbolSelectorState = useSelector((state: RootState) => state.symbolSelector);
  const { symbols: selectedSymbols } = useSelector((state: RootState) => state.symbolSelector);
  const snackId = useRef<SnackbarKey>();

  const {
    userConfig: {
      timezone: { value: timezoneValue },
      dateFormat: { value: dateFormat }
    }
  } = useAppSelector(selectUserConfig);

  const { dataHubs, activeDataHubId } = useSelector((state: RootState) => state.dataHub);
  const activeDataHub = dataHubs.find(dataHub => dataHub.id === activeDataHubId);
  const { symbols = [], name = '', exportSettings } = activeDataHub ?? {};
  const { formatDateString } = useDateText();

  const addSymbolsToExistingDataHub = (id: string) => {
    const updatedDataHub = dataHubs.find(dataHub => dataHub.id === id);

    if (updatedDataHub) {
      const symbolsInDataHub = updatedDataHub.symbols;

      const updatedSymbolArray = Array.from(
        new Map(
          [...symbolsInDataHub, ...selectedSymbols].map(symbol => [
            symbol.Code + symbol.Period + symbol.PeriodType + symbol.TimeRef,
            symbol
          ])
        ).values()
      );

      if (updatedSymbolArray.length > MAX_SELECTED_SYMBOLS) {
        dispatch(setLimitReached(true));
      } else {
        const difference = updatedSymbolArray.length - symbolsInDataHub.length;

        if (difference < 1) {
          callSuccessSnackbar(0);
        } else updateAndSave(updatedDataHub, updatedSymbolArray, difference);

        dispatch(setActiveDataHubId(id));
        dispatch(clearSelection());
        navigate('/data-hub');
        setTimeout(
          () => dispatch(fetchLpData({ symbols: updatedSymbolArray, timezone: timezoneValue })),
          100
        );
      }
    }
  };

  const updateAndSave = (
    updatedDataHub: DataHub,
    updatedSymbolArray: SelectedSymbol[],
    difference: number
  ) => {
    const copiedDataHub: DataHub = JSON.parse(JSON.stringify(updatedDataHub));
    copiedDataHub.symbols = updatedSymbolArray;
    copiedDataHub.savedAt = new Date().toISOString();

    const id = callLoadingSnackbar();
    snackId.current = id;
    dispatch(saveDataHub(copiedDataHub))
      .unwrap()
      .then(() => {
        if (snackId.current === id) {
          snackId.current = callSuccessSnackbar(difference);
        }
      })
      .catch(() => {
        snackId.current = callErrorSnackbar();
      });
  };

  const createDataHubName = () => {
    const formattedDate = formatDateString(new Date().toDateString(), DateTextVariants.FULL);
    let newName = `My Data ${formattedDate}`;
    let counter = 1;
    const existingNumbers = dataHubs
      .map(({ name }) => name)
      .filter(name => name.startsWith(newName))
      .map(name => {
        const match = name.match(/\((\d+)\)$/);
        return match ? parseInt(match[1], 10) : 0;
      });
    if (existingNumbers.length > 0) {
      counter = Math.max(...existingNumbers) + 1;
    }
    while (dataHubs.some(({ name }) => name.toLowerCase() === newName.toLowerCase())) {
      newName = `${newName} (${counter})`;
      counter++;
    }
    return newName;
  };

  const createNewDataHub = () => {
    const newUuid = generateUUID();
    const symbols = symbolSelectorState?.symbols;
    dispatch(
      saveDataHubs([
        {
          savedAt: new Date().toString(),
          symbols,
          name: createDataHubName(),
          id: newUuid,
          exportSettings: {
            from: null,
            to: null,
            periodNumber: '2',
            period: 'm',
            exportType: 'all',
            includeMetadata: false
          }
        },
        ...dataHubs
      ])
    );
    dispatch(setActiveDataHubId(newUuid));
    dispatch(clearSelection());
    navigate('/data-hub');
    setTimeout(() => dispatch(fetchLpData({ symbols, timezone: timezoneValue })), 100);
    enqueueSnackbar('Data Hub created!', {
      variant: 'success',
      autoHideDuration: 5000
    });
  };

  const deleteDataHub = (id: string) => {
    const dataHubsToRemain = dataHubs.filter(dataHub => dataHub.id !== id);
    dispatch(saveDataHubs(dataHubsToRemain));
    dispatch(clearLaunchpad());
    setTimeout(() => dispatch(setActiveDataHubId('')), 100);
    enqueueSnackbar('Data Hub deleted!', {
      variant: 'success',
      autoHideDuration: 5000
    });
  };

  const deleteSymbolFromDataHub = (columnKey: string) => {
    const [timeRef, period, periodType, code] = columnKey.split('-');

    const newSymbols = symbols.filter(
      symbol =>
        symbol.TimeRef !== timeRef ||
        symbol.Period !== period ||
        symbol.PeriodType !== periodType ||
        symbol.Code !== code
    );

    dispatch(
      saveDataHub({
        savedAt: new Date().toString(),
        name,
        id: activeDataHubId,
        symbols: newSymbols,
        exportSettings: exportSettings as ExportSettings
      })
    );
    dispatch(deleteSymbolFromLaunchpad(columnKey));
    enqueueSnackbar('Item removed from Data Hub!', {
      variant: 'success',
      autoHideDuration: 5000
    });
  };

  return { deleteDataHub, addSymbolsToExistingDataHub, createNewDataHub, deleteSymbolFromDataHub };
};

export default useDataHubSelector;

import { Cell } from '@tanstack/react-table';
import { Box } from '@mui/material';
import { CatalogItem } from 'slices/catalogSlice';
import useSelectionFunctions from 'components/SymbolSelector/useSelectionFunctions';
import { SelectedSymbol } from 'slices/symbolSelectorSlice';
import IndeterminateCheckbox from 'components/IndeterminateCheckbox';
import useCatalogTableCell from 'pages/Catalog/CatalogTable/CatalogTableRow/useCatalogTableCell';
import FactsheetDialogButton from 'components/FactsheetDialogButton';

const ForwardCatalogCell = ({
  cell,
  symbols
}: {
  cell: Cell<CatalogItem, unknown>;
  symbols: SelectedSymbol[];
}) => {
  const { isSymbolSelected, toggleSymbolInSelection } = useSelectionFunctions();

  const columnId = cell.column.id;
  const cellValue = cell.getValue() as string;
  const { renderContent, defaultStyle } = useCatalogTableCell(columnId, cell);

  const renderCellContent = () => {
    switch (columnId) {
      case 'Symbol': {
        return (
          <IndeterminateCheckbox
            firstSelected={!!symbols?.length && symbols.some(symbol => isSymbolSelected(symbol))}
            secondSelected={!!symbols?.length && symbols.every(symbol => isSymbolSelected(symbol))}
            onChange={() => {
              symbols?.forEach(symbol =>
                toggleSymbolInSelection(
                  symbol,
                  !!symbols?.length && symbols.some(symbol => isSymbolSelected(symbol))
                )
              );
            }}
          />
        );
      }
      case 'Index':
        return cellValue === 'Y' ? 'Index' : 'High, Mid, Low';
      case 'Periods':
        return cellValue?.toString().padStart(2, '0');
      case 'Factsheet':
        return (
          <Box
            width={'100%'}
            display={'flex'}
            justifyContent={'center'}
          >
            <FactsheetDialogButton code={cell.row.getValue('Code')} />
          </Box>
        );
      default:
        return renderContent();
    }
  };

  return (
    <div
      key={cell.id + columnId}
      style={defaultStyle}
    >
      {renderCellContent()}
    </div>
  );
};

export default ForwardCatalogCell;

import { useRef, useState, useEffect } from 'react';
import Tooltip from 'components/Tooltip';

const EllipsisWithTooltip = ({ text }: { text: string }) => {
  const spanRef = useRef<HTMLSpanElement>(null);
  const [isOverflowed, setIsOverflowed] = useState(false);

  useEffect(() => {
    if (spanRef.current) {
      const isOverflow = spanRef.current.scrollWidth > spanRef.current.offsetWidth;
      setIsOverflowed(isOverflow);
    }
  }, [text]);

  return (
    <div style={{ width: '100%' }}>
      <Tooltip
        variant='secondary'
        title={isOverflowed ? text : ''}
      >
        <span
          ref={spanRef}
          style={{
            display: 'inline-block',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '100%',
            marginTop: '5px'
          }}
        >
          {text}
        </span>
      </Tooltip>
    </div>
  );
};

export default EllipsisWithTooltip;

import { forwardRef } from 'react';
import { TableVirtuoso, TableComponents } from 'react-virtuoso';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { useSelector } from 'react-redux';
import useLaunchpadPreview from './useLaunchpadPreview';
import PreviewTooltipContent from './PreviewTooltipContent';
import { formatNumberWithPrecision } from 'utils/commonFunctions';
import DateText from 'components/DateText';
import Loader from 'components/Loader';
import Tooltip from 'components/Tooltip';
import { RootState } from 'store';
import ColumnHeader from 'pages/Launchpad/Preview/ColumnHeader';
import PremiumFeatureScreen from 'pages/Launchpad/UpgradeToPremiumScreen';
import { mockLpData } from 'pages/Launchpad/constants';
import useUserDetails from 'hooks/useUserAccess';
import EmptyLaunchpadScreen from 'pages/Launchpad/EmptyLaunchpadScreen';

const LaunchpadPreview = ({
  isSummary,
  setActiveTab
}: {
  isSummary?: boolean;
  setActiveTab: (index: number) => void;
}) => {
  const { lpData, loading } = useSelector((state: RootState) => state.launchpad);
  const { checkUserHasPremium } = useUserDetails();
  const flattenedLpData = lpData.toReversed().flat();
  const lpDataToShow = checkUserHasPremium() ? flattenedLpData : mockLpData;

  const { rows } = useLaunchpadPreview(lpDataToShow);

  const { dataHubs, activeDataHubId, status } = useSelector((state: RootState) => state.dataHub);
  const activeDataHub = dataHubs.find(dataHub => dataHub.id === activeDataHubId);
  const { symbols } = activeDataHub ?? {};

  if (loading)
    return (
      <>
        <Loader />
        <PremiumFeatureScreen />
      </>
    );

  if (!symbols?.length) return <EmptyLaunchpadScreen setActiveTab={setActiveTab} />;

  const columns = Array.from(
    new Set(
      lpDataToShow.map(
        ({ symbol, metadata }) =>
          `${symbol.TimeRef}-${symbol.Period}-${symbol.PeriodType}-${symbol.Code}-${metadata.Title}`
      )
    )
  ).map((columnKey, index) => {
    return {
      key: columnKey,
      displayComponent: (
        <ColumnHeader
          columnKey={columnKey}
          index={index}
          isSummary={isSummary}
        />
      )
    };
  });

  const VirtuosoTableComponents: TableComponents<Record<string, string>> = {
    Scroller: forwardRef<HTMLDivElement>((props, ref) => (
      <TableContainer
        sx={{ overflow: 'auto', height: '100%', width: '100%' }}
        component={Paper}
        {...props}
        ref={ref}
      />
    )),
    Table: props => (
      <Table
        {...props}
        sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }}
      />
    ),
    TableHead: forwardRef<HTMLTableSectionElement>((props, ref) => (
      <TableHead
        {...props}
        ref={ref}
        sx={{ '& .MuiTableCell-root': { verticalAlign: 'bottom' } }}
      />
    )),
    TableRow,
    TableBody: forwardRef<HTMLTableSectionElement>((props, ref) => (
      <TableBody
        {...props}
        ref={ref}
      />
    ))
  };

  function fixedHeaderContent() {
    return (
      <TableRow>
        <TableCell
          sx={{ width: 100, position: 'sticky', left: 0, backgroundColor: 'background.paper' }}
        />
        {columns.map(column => (
          <TableCell
            key={column.key}
            align='left'
            sx={{ backgroundColor: 'background.paper', width: 320, p: 1, px: 1.5 }}
          >
            {column.displayComponent}
          </TableCell>
        ))}
      </TableRow>
    );
  }

  const rowContent = (
    index: number,
    row: {
      date: string;
      values: Record<string, { value: string; PeriodAbs: string; FactsheetVersion: string }>;
    }
  ) => {
    const rowStyle = { backgroundColor: index % 2 === 0 ? 'secondary.main' : 'background.paper' };

    return (
      <>
        <TableCell
          sx={{
            width: 'fit-content',
            whiteSpace: 'nowrap',
            position: 'sticky',
            left: 0,
            ...rowStyle,
            px: 2
          }}
        >
          <DateText dateString={row.date} />
        </TableCell>
        {columns.map(column => {
          const cellData = row.values[column.key];

          return (
            <TableCell
              key={column.key}
              sx={rowStyle}
              align='center'
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  mx: '40%'
                }}
              >
                {cellData?.value ? (
                  <Tooltip
                    variant='secondary'
                    title={
                      <PreviewTooltipContent
                        code={column.key.split('-')[3]}
                        period={column.key.split('-')[1]}
                        periodType={column.key.split('-')[2]}
                        timeRef={column.key.split('-')[0]}
                        periodAbs={cellData.PeriodAbs || '-'}
                        factsheetVersion={cellData.FactsheetVersion || '-'}
                      />
                    }
                  >
                    {formatNumberWithPrecision(cellData?.value)}
                  </Tooltip>
                ) : (
                  '-'
                )}
              </Box>
            </TableCell>
          );
        })}
      </>
    );
  };

  return (
    <Box sx={{ display: 'flex', position: 'relative' }}>
      {!isSummary && <PremiumFeatureScreen />}
      <Paper sx={{ height: `calc(100vh - ${isSummary ? 298 : 232}px)`, width: '100%' }}>
        <TableVirtuoso
          // @ts-expect-error virtuoso typing
          data={rows}
          components={VirtuosoTableComponents}
          fixedHeaderContent={fixedHeaderContent}
          // @ts-expect-error virtuoso typing
          itemContent={(index, row) => rowContent(index, row)}
        />
      </Paper>
    </Box>
  );
};

export default LaunchpadPreview;

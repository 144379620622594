import {
  Box,
  Typography,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  List,
  ListItem
} from '@mui/material';
import { MutableRefObject, useState } from 'react';
import { useSelector } from 'react-redux';
import { KeyboardArrowDown, Launch } from '@mui/icons-material';
import { SelectorButton } from '..';
import { RootState } from 'store';
import { DataHub } from 'slices/dataHubSlice';
import { estimateLastSaved } from 'utils/commonFunctions';
import useDataHubSelector from 'pages/Launchpad/useDataHubSelector';

const DataHubItem = ({ dataHub }: { dataHub: DataHub }) => {
  const { addSymbolsToExistingDataHub } = useDataHubSelector();

  return (
    <ListItem
      onClick={() => {
        addSymbolsToExistingDataHub(dataHub.id);
      }}
      sx={theme => ({
        py: 1,
        pl: 1,
        pr: 0,
        mb: 1,
        height: 46,
        minWidth: 187,
        cursor: 'pointer',
        backgroundColor: theme.palette.text.primary + '33'
      })}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'flex-start'}
      >
        <Typography
          variant='caption'
          sx={{ fontWeight: 500 }}
          noWrap
        >
          {dataHub.name}
        </Typography>
        <Typography
          variant='caption'
          sx={{ fontSize: 10 }}
        >{`${dataHub.symbols.length} items, last saved ${estimateLastSaved(dataHub.savedAt)}`}</Typography>
      </Box>
    </ListItem>
  );
};

const DataHubsDropdown = ({
  popperAnchorRef
}: {
  popperAnchorRef: MutableRefObject<undefined>;
}) => {
  const [open, setOpen] = useState(false);

  const { dataHubs } = useSelector((state: RootState) => state.dataHub);

  const togglePopper = () => {
    setOpen(!open);
  };

  return (
    <>
      <SelectorButton
        sx={{ width: 187, mr: 0 }}
        disabled={dataHubs.length < 1}
        onClick={togglePopper}
      >
        <Launch />
        <Typography
          variant='caption'
          sx={{ mr: 0.5 }}
        >
          Add to existing Data Hub
        </Typography>
        <KeyboardArrowDown />
      </SelectorButton>
      <Popper
        open={dataHubs.length > 0 && open}
        anchorEl={popperAnchorRef.current}
        transition
        disablePortal
        placement='bottom-end'
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener
                onClickAway={() => {
                  setOpen(false);
                }}
              >
                <List
                  sx={{
                    width: '100%',
                    backgroundColor: 'primary.main',
                    px: 1,
                    pt: 0,
                    pb: 1,
                    '.MuiListItem-padding:last-of-type': { marginBottom: 0 },
                    '&::-webkit-scrollbar': {
                      width: '8px'
                    },
                    '&::-webkit-scrollbar-track': {
                      backgroundColor: 'primary.main'
                    },
                    '&::-webkit-scrollbar-thumb': {
                      borderRadius: '12px',
                      backgroundColor: '#6e729c',
                      backgroundClip: 'content-box',
                      height: 70
                    },
                    maxWidth: 360,
                    maxHeight: 162,
                    overflowY: 'scroll',
                    overflowX: 'hidden'
                  }}
                >
                  {dataHubs.map((dataHub: DataHub) => (
                    <DataHubItem
                      key={dataHub.name + dataHub.id}
                      dataHub={dataHub}
                    />
                  ))}
                </List>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default DataHubsDropdown;

import { useState } from 'react';
import { ButtonBase } from '@mui/material';
import { FeedOutlined } from '@mui/icons-material';
import FactsheetDialog from 'components/FactsheetDialog';

const FactsheetDialogButton = ({ code }: { code: string }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <FactsheetDialog
        code={code}
        open={open}
        setOpen={setOpen}
      />
      <ButtonBase
        onClick={e => {
          e.stopPropagation();
          setOpen(true);
        }}
      >
        <FeedOutlined sx={{ fontSize: 16 }} />
      </ButtonBase>
    </>
  );
};
export default FactsheetDialogButton;

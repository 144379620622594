import { Box, Typography } from '@mui/material';
import { UnauthenticatedMessage } from 'pages/Facthsheets/FactsheetView/FactsheetWindow/FactsheetBody';
import { FactsheetDetails } from 'pages/Facthsheets/constants';

const CriteriaTab = ({
  factsheet,
  gxToken
}: {
  factsheet: FactsheetDetails;
  gxToken: string | null;
}) => {
  let criteriaParsedLines: string[] = [];
  if (
    factsheet.factsheetVersionHistory.length > 0 &&
    factsheet.factsheetVersionHistory[0].criteriaForInclusion
  ) {
    criteriaParsedLines = factsheet.factsheetVersionHistory[0]?.criteriaForInclusion?.split('\n');
  }
  if (!gxToken) {
    return (
      <Box sx={{ pt: 5 }}>
        <UnauthenticatedMessage />
      </Box>
    );
  }
  return (
    <Box>
      {criteriaParsedLines.map(criteriaLine => {
        return (
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: 500,
              mb: 0.5
            }}
          >
            {criteriaLine}
          </Typography>
        );
      })}
    </Box>
  );
};
export default CriteriaTab;

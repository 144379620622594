import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import posthog from 'posthog-js';
import { RootState } from '../store';
import httpInstances from 'api/axiosInstance';

const CONFIG_NAME = 'userConfig';
export type Option = {
  label: string;
  value: string;
};
export type UserConfig = {
  timezone: Option;
  dateFormat: Option;
  defaultInsight: Option;
};

type UserConfigState = {
  status: 'idle' | 'loading' | 'failed' | 'fulfilled';
  error: string | null;
  userConfig: UserConfig;
};

type ErrorResponse = {
  message: string;
};
type Request = {
  userConfig: UserConfig;
};

const initialState: UserConfigState = {
  status: 'idle',
  error: null,
  userConfig: {
    timezone: { label: 'UTC', value: 'UTC' },
    dateFormat: { label: '14-Mar-25', value: 'dd-MMM-yy' },
    defaultInsight: { label: '', value: '' }
  }
};

export const getUserConfig = createAsyncThunk('getUserConfig', async (_, { rejectWithValue }) => {
  try {
    const { data } = await httpInstances.axiosInternalInstance.get(`/config/gxgo/` + CONFIG_NAME);
    posthog.capture('$set', {
      $set: { defaultInsight: data.defaultInsight.label }
    });
    return data;
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      const errorResponse = error.response.data;
      return rejectWithValue(errorResponse);
    }
    throw error;
  }
});

export const saveUserConfig = createAsyncThunk(
  'saveUserConfig',
  async (request: Request, { rejectWithValue }) => {
    try {
      const response = await postUserConfig(request.userConfig);
      if (response.status === 200) {
        posthog.capture('$set', {
          $set: { defaultInsight: request.userConfig.defaultInsight.label }
        });
        return request.userConfig;
      } else {
        return rejectWithValue("Couldn't save config file");
      }
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;
        return rejectWithValue(errorResponse);
      }
      throw error;
    }
  }
);
const userConfigSlice = createSlice({
  name: CONFIG_NAME,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getUserConfig.pending, state => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(getUserConfig.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.userConfig = { ...state.userConfig, ...action.payload };
      })
      .addCase(getUserConfig.rejected, (state, action) => {
        state.status = 'failed';
        if (action.payload) {
          state.error = (action.payload as ErrorResponse).message || 'Request failed';
        } else {
          state.error = action.error.message || 'Request failed';
        }
      })
      .addCase(saveUserConfig.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.userConfig = { ...state.userConfig, ...action.payload };
      })
      .addCase(saveUserConfig.rejected, (state, action) => {
        state.status = 'failed';
        if (action.payload) {
          state.error = (action.payload as ErrorResponse).message || 'Request failed';
        } else {
          state.error = action.error.message || 'Request failed';
        }
      });
  }
});

export const selectUserConfig = (state: RootState) => state.userConfig;
export default userConfigSlice.reducer;

const postUserConfig = async (config: object) => {
  const temp = { [CONFIG_NAME]: config };
  return httpInstances.axiosInternalInstance.post('/config/gxgo', temp);
};

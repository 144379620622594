import { Box, Typography, Link, ButtonBase } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
import useOnboarding from './useOnboarding';
import GoLogo from 'components/GoLogo';
import OnboardingLayout from 'layouts/OnboardingLayout';
import LoginComponent from 'pages/Onboarding/LoginComponent';

export const ThanksMessage = () => {
  const fontStyle = { mb: 2, fontSize: 32, fontWeight: 'bold' };
  return (
    <Box>
      <Typography sx={fontStyle}>Thank you! </Typography>
      <Typography sx={fontStyle}>We’ll be in touch shortly!</Typography>
    </Box>
  );
};

const OnboardingHeader = () => {
  return (
    <>
      <Typography sx={{ fontSize: 32, fontWeight: 700 }}>Start your free GX Go trial</Typography>
      <Typography sx={{ mb: 3, mt: 1, fontSize: 20 }}>No credit card required</Typography>
      <Typography sx={{ mb: 1.5, fontSize: 24, fontWeight: 700 }}>
        Let’s start with your work email...
      </Typography>
    </>
  );
};

const OnboardingFooter = () => {
  return (
    <>
      {' '}
      <Typography sx={{ fontWeight: 500 }}>
        Already have an account?{' '}
        <Link
          href='/login'
          rel='noopener noreferrer'
          color='primary.main'
          sx={{ fontWeight: 500, fontSize: 16, pl: 0.5, textDecoration: 'none' }}
        >
          {' '}
          Login
        </Link>
      </Typography>
    </>
  );
};

const Onboarding = () => {
  const { logoExpanded, resetPage, status } = useOnboarding();
  return (
    <OnboardingLayout>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          mt: '8%',
          ml: 4
        }}
      >
        {' '}
        <Box>
          <GoLogo open={logoExpanded} />
          {status !== 'idle' && status !== 'loading' && (
            <ButtonBase
              onClick={resetPage}
              sx={{ mt: 3 }}
            >
              <ChevronLeft fontSize='small' />
              <Typography
                variant='subtitle1'
                sx={{ fontSize: 16 }}
              >
                Back
              </Typography>
            </ButtonBase>
          )}
          <Box sx={{ maxWidth: '650px', mt: 3 }}>
            <LoginComponent
              emailButtonLabel={'Next'}
              loginHeader={<OnboardingHeader />}
              loginFooter={<OnboardingFooter />}
            />
          </Box>
        </Box>
      </Box>
    </OnboardingLayout>
  );
};

export default Onboarding;

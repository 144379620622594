import { useEffect, useRef, useState } from 'react';
import { ChartSeries } from '../../useChartSeries';
import useStockOptions from './useStockOptions';
import { ChartParentComponent } from 'pages/Insights/InsightsComponents/ChartsBlock/useChartFunctions';

const useStockChart = ({
  chartSeries,
  height,
  parent
}: {
  chartSeries: ChartSeries[] | undefined;
  height?: number;
  parent: ChartParentComponent;
}) => {
  const { createOptions } = useStockOptions({
    height
  });
  const options = chartSeries && createOptions(chartSeries, parent);
  const chartComponentRef = useRef(null);
  const [chartObject, setChartObject] = useState<Highcharts.Chart>();
  useEffect(() => {
    if (chartComponentRef.current) {
      const chartRef: any = chartComponentRef.current;
      setChartObject(chartRef.chart);
    }
  }, [chartComponentRef.current, chartSeries]);

  return { options, chartObject, chartComponentRef };
};

export default useStockChart;

import { useEffect, useState } from 'react';
import { ChartSeriesType } from 'pages/Insights/InsightsComponents/types';
import httpInstances from 'api/axiosInstance';
import useDateText, { DateTextVariants } from 'components/DateText/useDateText';

type ChartDataType = {
  symbols: {
    key: {
      Code: string;
      Period: string;
      PeriodType: string;
    };
    metadata: {
      Alias: string;
      Title: string;
      Currency: string;
    };
    series: [];
  }[];
};
export type ChartSeries = {
  showInNavigator: boolean;
  name: string;
  data: any;
  id: string;
  marker: any;
  metadata: any;
  color?: string;
};

const useChartSeries = (templateChartSeries: ChartSeriesType[] | undefined) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [chartData, setChartData] = useState<ChartDataType>();
  const [error, setError] = useState<boolean>(false);
  const [chartSeries, setChartSeries] = useState<ChartSeries[]>();
  const { formatDateString } = useDateText();

  useEffect(() => {
    if (templateChartSeries) {
      fetchChartData();
    }
  }, [templateChartSeries]);

  useEffect(() => {
    if (chartData) setChartSeries(createSeries());
  }, [chartData]);

  const fetchChartData = async () => {
    try {
      setLoading(true);
      setError(false);
      const symbols = templateChartSeries?.map(({ symbol }) => {
        const temp = JSON.parse(JSON.stringify(symbol));
        temp.overrideTime = '00:00';
        return temp;
      });
      const { data } = await httpInstances.axiosDataInstance.post(`/go/index/graph/spot`, {
        symbols
      });
      setChartData(data);
    } catch (error) {
      if (error?.response) {
        setError(true);
      }
    } finally {
      setLoading(false);
    }
  };

  function getDecimalPlaces(value: string | number): number {
    if (value === null) return 0; // Handle null/undefined
    const strValue = value.toString();
    return strValue.includes('.') ? strValue.split('.')[1].length : 0;
  }

  function createSeries(): ChartSeries[] | undefined {
    return chartData?.symbols?.flatMap(({ key, metadata, series }, symbolIndex) => {
      const Alias = metadata?.Alias;
      const Title = metadata?.Title;
      const shortTitle = getShortTitle(templateChartSeries, key);

      return Object.keys(series ?? {}).map((field: string, fieldIndex) => {
        const combinedIndex = symbolIndex * Object.keys(series).length + fieldIndex;
        // @ts-expect-error ---
        const fieldValue = series?.[field];
        const decimalPlaces =
          fieldValue?.[0]?.[1] !== null ? getDecimalPlaces(fieldValue[0][1]) : 0;
        // @ts-expect-error ---
        const tsData = series[field].map(tsData => {
          return {
            x: tsData[0],
            y: Number(tsData[1]),
            custom: {
              periodAbs: tsData.length === 3 ? parsePeriodAbs(tsData[2]) : '',
              decimalPlaces: decimalPlaces
            }
          };
        });

        return {
          showInNavigator: true,
          name: shortTitle || Alias || Title,
          data: tsData,
          id: (shortTitle || Alias || Title).split(' ').join('-') + '-' + field,
          marker: {
            radius: 1,
            symbol: 'circle'
          },
          lastVisiblePrice: {
            label: {
              formatter: function (this, value: number) {
                return new Intl.NumberFormat('en-US', {
                  minimumFractionDigits: decimalPlaces,
                  maximumFractionDigits: decimalPlaces
                }).format(value);
              }
            }
          },
          metadata: {
            decimalPlaces: decimalPlaces,
            hideName: Boolean(shortTitle),
            field: field,
            combinedIndex: combinedIndex,
            ...key,
            ...metadata
          }
        };
      });
    });
  }
  function getShortTitle(
    templateChartSeries: ChartSeriesType[] | undefined,
    key: { Code: string; Period: string; PeriodType: string }
  ): string | undefined {
    return templateChartSeries?.find(templateSeries => {
      const templateSeriesSymbol: any = templateSeries.symbol;
      return JSON.stringify(key) === JSON.stringify(templateSeriesSymbol.key);
    })?.shortTitle;
  }

  function parsePeriodAbs(periodAbs: string): string {
    try {
      if (/^\d/.test(periodAbs)) {
        if (periodAbs.length > 7) {
          return formatDateString(periodAbs, DateTextVariants.FULL);
        }
        return formatDateString(periodAbs, DateTextVariants.MONTH_YEAR);
      }
      return periodAbs;
    } catch (error) {
      console.log(error);
      return periodAbs;
    }
  }

  return {
    loading,
    error,
    chartSeries
  };
};

export default useChartSeries;

import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { FactsheetDetails } from 'pages/Facthsheets/constants';
import { UnauthenticatedMessage } from 'pages/Facthsheets/FactsheetView/FactsheetWindow/FactsheetBody';
import useUserDetails from 'hooks/useUserAccess';
import { ChartSeriesType } from 'pages/Insights/InsightsComponents/types';
import { mockTemplateChartSeries } from 'pages/Launchpad/constants';
import { getGxTokenFromStorage } from 'utils/commonFunctions';
import UnauthenticatedChart from 'pages/Facthsheets/FactsheetView/FactsheetWindow/FactsheetBody/Tabs/HistoryTab/UnauthenticatedChart';
import AuthenticatedChart from 'pages/Facthsheets/FactsheetView/FactsheetWindow/FactsheetBody/Tabs/HistoryTab/AuthenticatedChart';

export const FreeUserMessage = () => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
      <Typography
        variant='h5'
        sx={{ fontWeight: 600, fontSize: 24 }}
      >
        This is a Premium feature.{' '}
      </Typography>
      <Typography
        variant='h5'
        sx={{ fontWeight: 600, fontSize: 24 }}
      >
        Upgrade to GX Go Premium to enable charts and unlock advanced functionality.
      </Typography>
    </Box>
  );
};

const BlurBackdrop = ({ gxToken }: { gxToken: string | null }) => {
  const { checkUserHasPremium } = useUserDetails();
  const isPremiumUser = checkUserHasPremium();
  if (gxToken && isPremiumUser) {
    return null;
  }
  return (
    <>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backdropFilter: 'blur(4px)',
          zIndex: 1400
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          top: 'calc(30%)',
          left: 'calc(50% - 150px)',
          textAlign: 'center',
          zIndex: 1401,
          width: 300
        }}
      >
        {!gxToken && <UnauthenticatedMessage />}
        {gxToken && !isPremiumUser && <FreeUserMessage />}
      </Box>
    </>
  );
};
const HistoryTab = ({ factsheet }: { factsheet: FactsheetDetails }) => {
  const gxToken = getGxTokenFromStorage();
  const { checkUserHasPremium } = useUserDetails();
  const [templateChartSeries, setTemplateChartSeries] = useState<ChartSeriesType[]>([]);

  useEffect(() => {
    if (!checkUserHasPremium()) {
      setTemplateChartSeries(mockTemplateChartSeries);
    } else {
      const factsheetSeries: ChartSeriesType[] = factsheet.symbols.slice(0, 4).map(symbol => {
        return {
          settings: {
            type: 'line'
          },
          symbol: {
            group: 'Prod_Indexes',
            key: {
              Code: factsheet.code,
              Period: symbol.period,
              PeriodType: symbol.periodType,
              TimeRef: symbol.timeRef
            }
          },
          shortTitle: ''
        };
      });
      setTemplateChartSeries(factsheetSeries);
    }
  }, []);

  return (
    <Box
      sx={{
        position: 'relative'
      }}
    >
      <BlurBackdrop gxToken={gxToken} />
      {!gxToken && <UnauthenticatedChart code={factsheet.code} />}
      {gxToken && (
        <AuthenticatedChart
          code={factsheet.code}
          templateChartSeries={templateChartSeries}
        />
      )}
    </Box>
  );
};
export default HistoryTab;

import { Theme } from '@mui/material';
import { dateFormat } from 'highcharts';
import { useSelector } from 'react-redux';
import posthog from 'posthog-js';
import { RootState } from 'store';
import { detectEnvironment } from 'utils/commonFunctions';
import { ChartSeries } from 'pages/Insights/InsightsComponents/ChartsBlock/useChartSeries';

export const CHART_GRID_COLOR = '#333';

export interface ChartDownloadProperties {
  format: string;
  fullScreen?: boolean;
  darkMode?: boolean;
  chartLocation: ChartParentComponent | null;
}

export interface ChartParentComponent {
  mainPageTitle: string;
  subPageTitle?: string;
  tabName?: string;
}

const defaultProperties: ChartDownloadProperties = {
  format: '',
  fullScreen: false,
  darkMode: true,
  chartLocation: null
};

export const getBaseImageUrl = () => {
  const environment = detectEnvironment();
  return `https://${environment === 'staging' ? 'staging.' : ''}go.g-x.${environment !== 'dev' ? 'co' : 'dev'}`;
};

const useChartFunctions = () => {
  const sliceConfigFormat = (
    firstOccurrence: number,
    configFormat: string,
    lastOccurrence: number
  ) => {
    if (firstOccurrence === 0) {
      configFormat = configFormat.slice(lastOccurrence + 2, configFormat.length);
    } else if (lastOccurrence === configFormat.length - 1) {
      configFormat = configFormat.slice(0, firstOccurrence - 1);
    }
    return configFormat;
  };

  const { userConfig } = useSelector((state: RootState) => state.userConfig);

  const getUserDateFormat = () => {
    let configFormat = userConfig?.dateFormat.value;
    const firstOccurrence = configFormat.indexOf('d');
    const lastOccurrence = configFormat.lastIndexOf('d');

    configFormat = sliceConfigFormat(firstOccurrence, configFormat, lastOccurrence);
    const delimiter = configFormat.includes('-') ? '-' : '/';
    const configParts = configFormat.split(delimiter);
    configFormat = configParts
      .map(config => {
        switch (config.toLocaleLowerCase()) {
          case 'mmm': {
            return '%b';
          }
          case 'mm': {
            return '%m';
          }
          case 'yy': {
            return '%y';
          }
          case 'yyyy': {
            return '%Y';
          }
          default:
            return '';
        }
      })
      .join(delimiter);
    return configFormat;
  };
  const userDateFormat = getUserDateFormat();
  const createNavigator = (theme: Theme) => {
    return {
      xAxis: {
        gridLineColor: theme.palette.info.dark,
        labels: {
          style: {
            color: theme.palette.text.primary
          },
          formatter: function (): string {
            // @ts-expect-error: highcharts rules.
            const value = this.value;
            return dateFormat(userDateFormat, value);
          }
        }
      },
      enabled: true,
      adaptToUpdatedData: false,
      scrollbar: { enabled: true, barBackgroundColor: theme.palette.info.dark },
      outlineColor: theme.palette.info.dark,
      handles: {
        backgroundColor: theme.palette.background.default,
        borderColor: theme.palette.info.dark
      }
    };
  };

  function createPlotOptions() {
    const plotOptions: Highcharts.PlotOptions = {};
    plotOptions.series = {
      animation: false,
      states: {
        inactive: {
          opacity: 1
        },
        hover: {
          enabled: false
        }
      }
    };
    plotOptions.line = {
      states: {
        hover: {
          lineWidth: 3
        }
      }
    };
    return plotOptions;
  }

  function createYAxis(color: string) {
    return {
      maxPadding: 0.1,
      offset: 35,
      labels: {
        style: {
          color: color,
          fontSize: '10px'
        },
        formatter: function (): string {
          // @ts-expect-error: highcharts rules.
          return this.value.toFixed(2);
        }
      },
      gridLineColor: CHART_GRID_COLOR
    };
  }

  function createXAxis(color: string) {
    return {
      tickInterval: 30 * 24 * 3600 * 1000,
      overscroll: '2px',
      type: 'datetime',
      ordinal: false,
      labels: {
        formatter: function (): string {
          // @ts-expect-error: highcharts rules.
          const value = this.value;
          return dateFormat(userDateFormat, value);
        },
        style: {
          color: color,
          fontSize: '10px'
        }
      },
      gridLineColor: CHART_GRID_COLOR,
      lineColor: CHART_GRID_COLOR,
      tickColor: CHART_GRID_COLOR
    };
  }

  function captureChartDownloadAction(
    chartSeries: ChartSeries[],
    properties: ChartDownloadProperties
  ) {
    const chartSymbols = chartSeries.map(s => ({
      Code: s.metadata.Code,
      Period: s.metadata.Period,
      PeriodType: s.metadata.PeriodType,
      TimeRef: s.metadata.TimeRef
    }));

    const mergedProperties: ChartDownloadProperties = { ...defaultProperties, ...properties };

    posthog.capture('chart_download_button_click', {
      symbols: chartSymbols,
      ...mergedProperties
    });
  }

  return {
    createNavigator,
    createPlotOptions,
    createYAxis,
    createXAxis,
    captureChartDownloadAction
  };
};

export default useChartFunctions;

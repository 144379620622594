import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../store';
import useSelectionFunctions from 'components/SymbolSelector/useSelectionFunctions';
import { useAppDispatch } from 'hooks/redux-hooks';
import { FactsheetDetails } from 'pages/Facthsheets/constants';
import { SelectedSymbol, setLimitReached } from 'slices/symbolSelectorSlice';
import { MAX_SELECTED_SYMBOLS } from 'utils/constants';

const useItemsTab = (factsheet: FactsheetDetails) => {
  const { addAllSymbolsToSelection, allSymbolsSelected, removeSymbolsFromSelection } =
    useSelectionFunctions();
  const dispatch = useAppDispatch();
  const symbolSelectorState = useSelector((state: RootState) => state.symbolSelector);

  const getSymbolsFrom = (factsheet: FactsheetDetails): SelectedSymbol[] => {
    return factsheet.symbols.map(symbol => {
      return {
        Code: factsheet.code,
        Period: symbol.period,
        PeriodType: symbol.periodType,
        TimeRef: symbol.timeRef
      };
    });
  };

  const allItemsSelected = (): boolean => {
    const symbols = getSymbolsFrom(factsheet);
    if (symbols.length < 1) return false;
    return allSymbolsSelected(symbols);
  };

  const clearAllCodesFromSelection = () => {
    removeSymbolsFromSelection(getSymbolsFrom(factsheet));
  };

  const addAllCodesToSelection = () => {
    const newSymbols = getSymbolsFrom(factsheet);
    if (symbolSelectorState.symbols.length + newSymbols.length > MAX_SELECTED_SYMBOLS) {
      const symbolsToAdd = newSymbols.slice(
        0,
        MAX_SELECTED_SYMBOLS - symbolSelectorState.symbols.length
      );
      addAllSymbolsToSelection(symbolsToAdd);
      dispatch(setLimitReached(true));
    } else {
      addAllSymbolsToSelection(newSymbols);
    }
  };
  const handleAllItemsSelection = () => {
    if (allItemsSelected()) {
      clearAllCodesFromSelection();
    } else {
      addAllCodesToSelection();
    }
  };
  return { handleAllItemsSelection, allItemsSelected };
};
export default useItemsTab;

import React from 'react';
import { Provider as ReduxStoreProvider } from 'react-redux';
import { HistoryRouter } from 'redux-first-history/rr6';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { history, store } from './store';
import AppRoutes from './AppRoutes';
import { darkModeTheme } from 'utils/mui';
import { TrackingLister } from 'components/Tracking';
import SymbolSelector from 'components/SymbolSelector';
import CookieBanner from 'components/CookieBanner';
import ResponseMessage from 'components/ResponseMessage';
import './App.css';

const App: React.FC = () => {
  return (
    <ThemeProvider theme={darkModeTheme}>
      <ReduxStoreProvider store={store}>
        <SnackbarProvider
          maxSnack={1}
          Components={{
            loading: ResponseMessage,
            error: ResponseMessage,
            success: ResponseMessage,
            info: ResponseMessage
          }}
        >
          <CssBaseline />
          <HistoryRouter history={history}>
            <SymbolSelector />
            <TrackingLister>
              <CookieBanner>
                <AppRoutes />
              </CookieBanner>
            </TrackingLister>
          </HistoryRouter>
        </SnackbarProvider>
      </ReduxStoreProvider>
    </ThemeProvider>
  );
};

export default App;

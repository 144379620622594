import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'hooks/redux-hooks';
import { getInsights } from 'slices/insightsSlice';
import { RootState } from 'store';

const useInsights = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useAppDispatch();
  const { insightsList, status: insightListStatus } = useSelector(
    (state: RootState) => state.insightsList
  );

  const { userConfig, status: configStatus } = useSelector((state: RootState) => state.userConfig);
  const [activeTab, setActiveTab] = useState(0);

  const insightExists = (insightId: string) => {
    return insightsList.some(insight => insight.insightId === insightId);
  };

  useEffect(() => {
    // if the insightId is not in lowercase, redirect to the lowercase version
    if (params.insightId && params.insightId.toLowerCase() !== params.insightId) {
      if (params.tabId) {
        navigate(`/markets/${params.insightId.toLowerCase()}/${params.tabId}`);
      } else {
        navigate(`/markets/${params.insightId.toLowerCase()}`);
      }
    } else if (
      !params?.insightId &&
      insightListStatus === 'fulfilled' &&
      configStatus !== 'loading'
    ) {
      let insightId = insightsList[0]?.insightId;
      if (configStatus === 'fulfilled') {
        const defaultInsight = userConfig.defaultInsight.value;
        if (defaultInsight.length && checkInsightInList(defaultInsight.toLowerCase())) {
          insightId = defaultInsight.toLowerCase();
        }
      }
      navigate(`/markets/${insightId}`);
    } else if (
      params?.insightId &&
      insightListStatus === 'fulfilled' &&
      !insightExists(params.insightId)
    ) {
      navigate(`/notfound`);
    }
  }, [insightsList, configStatus]);

  useEffect(() => {
    fetchInsights();
  }, [params.insightId, insightsList, configStatus]);

  const fetchInsights = () => {
    if (params?.insightId && configStatus !== 'loading') {
      dispatch(getInsights({ insightId: params.insightId, timezone: userConfig.timezone.value }));
    }
  };

  const checkInsightInList = (defaultInsight: string) => {
    return insightsList.some(insight => insight.insightId === defaultInsight);
  };

  return {
    activeTab,
    setActiveTab
  };
};

export default useInsights;

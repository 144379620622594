import { useSelector } from 'react-redux';
import {
  CommoditiesTableRowType,
  CommoditiesTableSectionType
} from 'pages/Insights/InsightsComponents/types';
import { createSelectedSymbol, SelectedSymbol, setLimitReached } from 'slices/symbolSelectorSlice';
import useSelectionFunctions from 'components/SymbolSelector/useSelectionFunctions';
import { MAX_SELECTED_SYMBOLS } from 'utils/constants';
import { useAppDispatch } from 'hooks/redux-hooks';
import { RootState } from 'store';

const useCommoditiesSelection = (sections: CommoditiesTableSectionType[]) => {
  const {
    addSymbolsArrayToSelection,
    addAllSymbolsToSelection,
    allSymbolsSelected,
    removeSymbolFromSelection,
    removeSymbolsFromSelection,
    addSymbolToSelection
  } = useSelectionFunctions();
  const dispatch = useAppDispatch();
  const symbolSelectorState = useSelector((state: RootState) => state.symbolSelector);

  const getSymbolsFrom = (row: CommoditiesTableRowType) => {
    const symbols: Array<SelectedSymbol> = [];
    const last = createSelectedSymbol(row.symbol.key);
    const diff = createSelectedSymbol(row.diffSymbol.key);
    if (last.Code) {
      symbols.push(last);
    }
    if (diff.Code) {
      symbols.push(diff);
    }
    return symbols;
  };

  const isSectionSelected = (rows: CommoditiesTableRowType[]): boolean => {
    const symbols = rows
      .filter(row => row.last || row.diff)
      .map(row => getSymbolsFrom(row))
      .flatMap(symbol => symbol);
    if (symbols.length < 1) return false;
    return allSymbolsSelected(symbols);
  };

  const toggleSectionInSelection = (
    rows: CommoditiesTableRowType[],
    sectionSelected: boolean = false
  ) => {
    if (sectionSelected) removeSectionFromSelection(rows);
    else addSectionToSelection(rows);
  };

  const removeSectionFromSelection = (rows: CommoditiesTableRowType[]) => {
    for (const row of rows) {
      const last = createSelectedSymbol(row.symbol.key);
      const diff = createSelectedSymbol(row.diffSymbol.key);
      removeSymbolFromSelection(last);
      removeSymbolFromSelection(diff);
    }
  };

  const addSectionToSelection = (rows: CommoditiesTableRowType[]) => {
    const symbols: SelectedSymbol[] = [];

    for (const row of rows) {
      symbols.push(...getSymbolsFrom(row));
    }

    if (symbolSelectorState.symbols.length + symbols.length > MAX_SELECTED_SYMBOLS) {
      const symbolsToAdd = symbols.slice(
        0,
        MAX_SELECTED_SYMBOLS - symbolSelectorState.symbols.length
      );
      symbolsToAdd.forEach(symbol => addSymbolToSelection(symbol));
      dispatch(setLimitReached(true));
    } else {
      symbols.forEach(symbol => addSymbolToSelection(symbol));
    }
  };

  const toggleCodeInSelection = (row: CommoditiesTableRowType, codeSelected: boolean): void => {
    const symbols = getSymbolsFrom(row);
    if (codeSelected) {
      symbols.forEach(symbol => removeSymbolFromSelection(symbol));
    } else {
      const symbols = getSymbolsFrom(row);
      addSymbolsArrayToSelection(symbols);
    }
  };

  const getAllSymbolsInTab = () =>
    sections
      .map(({ rows }) => {
        return rows
          .filter(row => row.last || row.diff)
          .map(row => {
            return getSymbolsFrom(row);
          })
          .flatMap(symbol => symbol);
      })
      .flatMap(symbol => symbol);

  const addAllCodesToSelection = () => {
    const newSymbols = getAllSymbolsInTab();
    if (symbolSelectorState.symbols.length + newSymbols.length > MAX_SELECTED_SYMBOLS) {
      const symbolsToAdd = newSymbols.slice(
        0,
        MAX_SELECTED_SYMBOLS - symbolSelectorState.symbols.length
      );
      addAllSymbolsToSelection(symbolsToAdd);
      dispatch(setLimitReached(true));
    } else {
      addAllSymbolsToSelection(newSymbols);
    }
  };

  const clearAllCodesFromSelection = () => {
    removeSymbolsFromSelection(getAllSymbolsInTab());
  };

  const allCodesSelected = () => {
    if (sections?.length < 1) return false;
    return sections?.every(section => {
      return isSectionSelected(section.rows);
    });
  };

  return {
    toggleSectionInSelection,
    allCodesSelected,
    addAllCodesToSelection,
    clearAllCodesFromSelection,
    isSectionSelected,
    toggleCodeInSelection
  };
};

export default useCommoditiesSelection;

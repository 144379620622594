import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useLaunchpad from './useLaunchpad';
import LaunchpadSummary from './Summary';
import ActionBar from './ActionBar';
import LaunchpadPreview from './Preview';
import LaunchpadGraph from './Graph';
import LaunchpadExport from './Export';
import MainLayout from 'layouts/MainLayout';
import Tabs from 'components/Tabs';
import TabPanel from 'components/TabPanel';
import { RootState } from 'store';
import useUserDetails from 'hooks/useUserAccess';
import { mockTemplateChartSeries } from 'pages/Launchpad/constants';
import EditableName from 'pages/Launchpad/EditableName';

const Launchpad = () => {
  const { activeTab, setActiveTab, chartSeries, isLaunchpadEmpty } = useLaunchpad();
  const { loading, lpData } = useSelector((state: RootState) => state.launchpad);

  const { dataHubs, activeDataHubId, status } = useSelector((state: RootState) => state.dataHub);
  const activeDataHub = dataHubs.find(dataHub => dataHub.id === activeDataHubId);
  const { name = '' } = activeDataHub ?? {};
  const { checkUserHasPremium } = useUserDetails();
  const location = useLocation();

  useEffect(() => {
    const paths = location.pathname.split('/');

    if (paths.length === 3) {
      launchpadTabs.map(({ name }, index) => {
        if (paths[2].toLocaleLowerCase().includes(name.toLocaleLowerCase())) {
          setActiveTab(index);
        }
      });
    }
  }, [location.pathname]);

  const isDataHubLoading = status === 'loading';

  const disabled = loading || isDataHubLoading;

  const launchpadTabs = [
    {
      name: 'Summary',
      disabled
    },
    {
      name: 'Preview',
      disabled
    },
    {
      name: 'Chart',
      disabled
    },
    {
      name: 'Export',
      disabled
    }
  ];

  const chartSeriesToShow = checkUserHasPremium() ? chartSeries : mockTemplateChartSeries;

  const returnTabPanelContent = () => {
    switch (activeTab) {
      case 1:
        return <LaunchpadPreview setActiveTab={setActiveTab} />;
      case 2:
        return (
          <LaunchpadGraph
            setActiveTab={setActiveTab}
            templateChartSeries={chartSeriesToShow}
            parent={{
              mainPageTitle: 'Data Hub',
              tabName: 'Chart'
            }}
          />
        );
      case 3:
        return <LaunchpadExport setActiveTab={setActiveTab} />;
      default:
        return (
          <LaunchpadSummary
            // @ts-expect-error type issue
            chartSeries={chartSeriesToShow}
            setActiveTab={setActiveTab}
            isLaunchpadEmpty={isLaunchpadEmpty}
          />
        );
    }
  };

  useEffect(() => {
    document.title = 'GX Go | Data Hub';
    return () => {
      document.title = 'GX Go';
    };
  }, []);

  useEffect(() => {
    if (!lpData.length) setActiveTab(0);
  }, [lpData]);

  return (
    <MainLayout>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mt: 3 }}>
        <Box sx={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'flex-end' }}>
          <Typography
            variant='h2'
            sx={{ mr: 3 }}
            noWrap
          >
            Data Hub
          </Typography>
          {name && <EditableName />}
        </Box>
        <Tabs
          mainPageTitle='Data Hub'
          tabs={launchpadTabs}
          minTabWidth={145}
          activeTab={activeTab}
          onChange={setActiveTab}
        />
      </Box>
      <ActionBar
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      {launchpadTabs.map(({ name }, index) => (
        <TabPanel
          key={name}
          index={index}
          value={activeTab}
        >
          {returnTabPanelContent()}
        </TabPanel>
      ))}
    </MainLayout>
  );
};

export default Launchpad;

import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { RootState } from 'store';
import { getGxTokenFromStorage, getTripTokenFromStorage } from 'utils/commonFunctions';
import NotFound from 'pages/NotFound';

const UnprotectedLayout = () => {
  const { status } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const gxToken = getGxTokenFromStorage();
  const tripToken = getTripTokenFromStorage();

  if (status === 'failed') {
    if (!!gxToken && !!tripToken) {
      navigate('/logout');
      return null;
    }
    return <NotFound />;
  }
  return <Outlet />;
};

export default UnprotectedLayout;

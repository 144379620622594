import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SelectedSymbol } from 'slices/symbolSelectorSlice';
import httpInstances from 'api/axiosInstance';
import { LpDataItem } from 'pages/Launchpad/useLaunchpad';

export type LpData = LpDataItem[][];

type LaunchpadSelectionState = {
  lpData: LpData;
  loading: boolean;
  error: boolean;
};

const initialState: LaunchpadSelectionState = {
  lpData: [],
  loading: false,
  error: false
};

export const fetchLpData = createAsyncThunk(
  'launchpad/fetchLpData',
  async (
    { symbols, timezone }: { symbols: SelectedSymbol[]; timezone: string },
    { rejectWithValue }
  ) => {
    try {
      const requests = symbols.map(({ Code, Period, PeriodType, TimeRef }) =>
        Code
          ? httpInstances.axiosDataInstance.get(
              `/index?code=${Code}&period=${Period}&periodType=${PeriodType}&timeRef=${TimeRef}&from=all&metadata=true&timezone=${timezone}`
            )
          : null
      );
      const responses = await Promise.all(requests.filter(Boolean));
      return responses.map(response => response?.data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const launchpadSlice = createSlice({
  name: 'launchpad',
  initialState,
  extraReducers: builder => {
    builder
      .addCase(fetchLpData.pending, state => {
        state.loading = true;
        state.error = false;
      })
      .addCase(fetchLpData.fulfilled, (state, action: PayloadAction<LpData>) => {
        state.lpData = action.payload;
        state.loading = false;
      })
      .addCase(fetchLpData.rejected, state => {
        state.loading = false;
        state.error = true;
      });
  },
  reducers: {
    deleteSymbolFromLaunchpad(state, action: PayloadAction<string>) {
      const [timeRef, period, periodType, code] = action.payload.split('-');

      state.lpData = state.lpData.filter(dataGroup =>
        dataGroup.every(
          item =>
            !(
              item.symbol.TimeRef === timeRef &&
              item.symbol.Period === period &&
              item.symbol.PeriodType === periodType &&
              item.symbol.Code === code
            )
        )
      );
    },
    setLpData(state, action: PayloadAction<LpData>) {
      state.lpData = action.payload;
    },
    clearLaunchpad(state) {
      state.lpData = [];
      state.loading = false;
      state.error = false;
    }
  }
});

export const { clearLaunchpad, setLpData, deleteSymbolFromLaunchpad } = launchpadSlice.actions;

export default launchpadSlice.reducer;

import { Box, ButtonBase, Paper } from '@mui/material';
import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Tabs from 'components/Tabs';
import TabPanel from 'components/TabPanel';
import FactsheetWindow from 'pages/Facthsheets/FactsheetView/FactsheetWindow';

export const FactsheetView = ({
  factsheetKeys,
  setOpen,
  setAdditionalTabsParam
}: {
  factsheetKeys: string;
  setOpen?: (open: boolean) => void;
  setAdditionalTabsParam?: (tabs: string[]) => void;
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [openedFactsheets, setOpenedFactsheets] = useState<string[]>([]);
  const [tabNames, setTabNames] = useState<Map<string, string>>(new Map());

  useEffect(() => {
    const codes = factsheetKeys.split(',');
    setOpenedFactsheets(codes);
    setTabNames(new Map(codes.map(code => [code, code])));
  }, [factsheetKeys]);

  const onClose = (value: number) => {
    const temp = openedFactsheets.filter((f, index) => {
      return index !== value;
    });
    if (setAdditionalTabsParam) {
      setAdditionalTabsParam(temp.slice(1, temp.length));
    }
    setOpenedFactsheets(temp);
    if (value <= activeTab) {
      setActiveTab(activeTab - 1);
    }
  };

  const updateTabName = (code: string, newName: string) => {
    setTabNames(prevTabNames => {
      const updatedTabNames = new Map(prevTabNames);
      updatedTabNames.set(code, newName);
      return updatedTabNames;
    });
  };

  const openNewTab = (code: string) => {
    const temp = [...openedFactsheets];
    const openedFactsheetIndex = openedFactsheets.indexOf(code);
    if (openedFactsheetIndex > -1) {
      setActiveTab(openedFactsheetIndex);
    } else {
      temp.push(code);
      if (setAdditionalTabsParam && temp.length > 1) {
        setAdditionalTabsParam(temp.slice(1, temp.length));
      }
      setOpenedFactsheets(temp);
      setActiveTab(temp.length - 1);
      setTabNames(prevTabNames => {
        const updatedTabNames = new Map(prevTabNames);
        updatedTabNames.set(code, code);
        return updatedTabNames;
      });
    }
  };

  return (
    <Paper sx={{ maxWidth: 1024, minWidth: 700, maxHeight: 950, mx: 8, my: 5 }}>
      <Box sx={{ px: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Tabs
            mainPageTitle={'factsheets'}
            tabs={openedFactsheets.map((code, index) => ({
              name: tabNames.get(code) ?? code,
              closable: !!index,
              onClose
            }))}
            activeTab={activeTab}
            onChange={setActiveTab}
          />
          {setOpen && (
            <ButtonBase
              sx={{ width: 24, height: 24 }}
              onClick={event => {
                event.stopPropagation();
                setOpen(false);
              }}
              aria-label='close'
            >
              <CloseIcon />
            </ButtonBase>
          )}
        </Box>
        {openedFactsheets.map((code, index) => (
          <TabPanel
            key={code + index}
            index={index}
            value={activeTab}
          >
            <FactsheetWindow
              factsheetKey={code}
              openNewTab={openNewTab}
              updateTabName={updateTabName}
            />
          </TabPanel>
        ))}
      </Box>
    </Paper>
  );
};
export default FactsheetView;

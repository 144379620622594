import { Box, Typography } from '@mui/material';

const PreviewTooltipContent = ({
  timeRef,
  period,
  periodType,
  code,
  periodAbs,
  factsheetVersion
}: {
  timeRef: string;
  period: string;
  periodType: string;
  code: string;
  periodAbs?: string;
  factsheetVersion?: string;
}) => (
  <Box sx={{ display: 'flex' }}>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        minWidth: 80
      }}
    >
      <Typography
        color='info'
        variant='inherit'
      >
        GX Code
      </Typography>
      <Typography
        variant='inherit'
        sx={{ mb: 0.5 }}
      >
        {code}
      </Typography>
      <Typography
        color='info'
        variant='inherit'
      >
        PeriodType
      </Typography>
      <Typography
        variant='inherit'
        sx={{ mb: 0.5 }}
      >
        {periodType}
      </Typography>
      {periodAbs && (
        <>
          <Typography
            color='info'
            variant='inherit'
          >
            PeriodAbs
          </Typography>
          <Typography variant='inherit'>{periodAbs}</Typography>
        </>
      )}
    </Box>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Typography
        color='info'
        variant='inherit'
      >
        TimeRef
      </Typography>
      <Typography
        variant='inherit'
        sx={{ mb: 0.5 }}
      >
        {timeRef}
      </Typography>
      <Typography
        color='info'
        variant='inherit'
      >
        Period
      </Typography>
      <Typography
        variant='inherit'
        sx={{ mb: 0.5 }}
      >
        {period}
      </Typography>
      {factsheetVersion && (
        <>
          <Typography
            color='info'
            variant='inherit'
          >
            FactsheetVersion
          </Typography>
          <Typography variant='inherit'>{factsheetVersion}</Typography>
        </>
      )}
    </Box>
  </Box>
);

export default PreviewTooltipContent;

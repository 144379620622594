import { Box, Divider, Typography } from '@mui/material';
import { UnauthenticatedMessage } from 'pages/Facthsheets/FactsheetView/FactsheetWindow/FactsheetBody';
import { FactsheetDetails } from 'pages/Facthsheets/constants';

const ApproachTab = ({
  factsheet,
  gxToken
}: {
  factsheet: FactsheetDetails;
  gxToken: string | null;
}) => {
  if (!gxToken) {
    return (
      <Box sx={{ pt: 5 }}>
        {' '}
        <UnauthenticatedMessage />
      </Box>
    );
  } else
    return (
      <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
        <Box
          display={'flex'}
          sx={{ minWidth: '50%', maxWidth: '50%', flexDirection: 'column', pl: 1, pr: 2 }}
        >
          <Box
            display={'flex'}
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              py: 0.5
            }}
          >
            <Typography
              variant='body2'
              sx={{ fontWeight: 700 }}
            >
              Trade Data Approach
            </Typography>
            <Typography
              variant='body2'
              sx={{ fontWeight: 700 }}
            >
              Y/N
            </Typography>
          </Box>
          <Divider sx={{ backgroundColor: 'text.primary', pr: 4 }} />
          <Box
            display={'flex'}
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              pt: 0.5
            }}
          >
            <Typography>Priority to reported and concluded transactions</Typography>
            <Typography>{factsheet.priorityToTransactions ? 'Y' : 'N'}</Typography>
          </Box>
          <Box
            display={'flex'}
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              pt: 0.5
            }}
          >
            <Typography>Sole-sourced data from trading principals accepted</Typography>
            <Typography>{factsheet.soleSourcedDataAccepted ? 'Y' : 'N'}</Typography>
          </Box>
          <Box
            display={'flex'}
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              pt: 0.5
            }}
          >
            <Typography>Inter-affiliate data accepted as valid</Typography>
            <Typography>{factsheet.interAffiliateDataAccepted ? 'Y' : 'N'}</Typography>
          </Box>
        </Box>
        <Box
          display={'flex'}
          sx={{ flexDirection: 'column', pr: 1, pl: 2 }}
        >
          {factsheet.factsheetVersionHistory.length > 0 &&
            factsheet.factsheetVersionHistory[0].calculationApproach && (
              <Typography>{factsheet.factsheetVersionHistory[0].calculationApproach}</Typography>
            )}
        </Box>
      </Box>
    );
};
export default ApproachTab;

import { Dispatch, SetStateAction, useState } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import StepButtons from 'pages/Onboarding/OnboardingForm/Steps/StepButtons';

const AboutUser = ({
  firstName,
  setFirstName,
  lastName,
  setLastName,
  companyName,
  setCompanyName,
  activeStep,
  setActiveStep
}: {
  firstName: string;
  setFirstName: Dispatch<SetStateAction<string>>;
  lastName: string;
  setLastName: Dispatch<SetStateAction<string>>;
  companyName: string;
  setCompanyName: Dispatch<SetStateAction<string>>;
  activeStep: number;
  setActiveStep: Dispatch<SetStateAction<number>>;
}) => {
  const [firstNameError, setFirstNameError] = useState<string>('');
  const [lastNameError, setLastNameError] = useState<string>('');
  const [companyNameError, setCompanyNameError] = useState<string>('');

  const validateForm = () => {
    let isValid = true;
    if (firstName.length < 1) {
      setFirstNameError('Please provide first name');
      isValid = false;
    }
    if (lastName.length < 1) {
      setLastNameError('Please provide last name');
      isValid = false;
    }
    if (companyName.length < 1) {
      setCompanyNameError('Please provide company name');
      isValid = false;
    }
    return isValid;
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography sx={{ fontSize: 24, fontWeight: 'bold', mb: 1.5 }}>
        Great! Please tell us about yourself...
      </Typography>
      <TextField
        variant='filled'
        required
        autoComplete='text'
        id='firstName'
        label='First Name'
        name='firstName'
        sx={{ minWidth: 220, mb: 2 }}
        value={firstName}
        onChange={e => {
          if (firstNameError) setFirstNameError('');
          setFirstName(e.target.value);
        }}
        error={!!firstNameError}
        helperText={firstNameError}
      />
      <TextField
        variant='filled'
        required
        autoComplete='text'
        id='lastName'
        label='Last Name'
        name='lastName'
        sx={{ minWidth: 220, mb: 2 }}
        value={lastName}
        onChange={e => {
          if (lastNameError) setLastNameError('');
          setLastName(e.target.value);
        }}
        error={!!lastNameError}
        helperText={lastNameError}
      />

      <TextField
        variant='filled'
        required
        autoComplete='companyName'
        id='companyName'
        label='Company Name'
        name='companyName'
        sx={{ minWidth: 220, mb: 4 }}
        value={companyName}
        onChange={e => {
          if (companyNameError) setCompanyNameError('');
          setCompanyName(e.target.value);
        }}
        error={!!companyNameError}
        helperText={companyNameError}
      />
      <StepButtons
        activeStep={activeStep}
        onBackClick={() => {
          setActiveStep(activeStep - 1);
        }}
        onNextClick={() => {
          if (validateForm()) {
            setActiveStep(activeStep + 1);
          }
        }}
      />
    </Box>
  );
};
export default AboutUser;

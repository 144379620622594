import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import posthog from 'posthog-js';
import { RootState } from '../../../store';
import { useAppDispatch } from 'hooks/redux-hooks';
import { createUser, resetStatus, User } from 'slices/userCreationSlice';
import { getGxTokenFromStorage, setGxTokenToStorage } from 'utils/commonFunctions';
import AboutUser from 'pages/Onboarding/OnboardingForm/Steps/AboutUser';
import DefaultMarket from 'pages/Onboarding/OnboardingForm/Steps/DefaultMarket';
import Invite from 'pages/Onboarding/OnboardingForm/Steps/Invite';
import JobTitle from 'pages/Onboarding/OnboardingForm/Steps/JobTitle';
import httpInstances from 'api/axiosInstance';
import {
  identifyUserInPostHog,
  initializePostHog
} from 'components/Tracking/PostHog/postHogTrackingListener';

export const EMAIL_PARAM = 'email';
export const VERIFICATION_TOKEN_PARAM = 'verificationToken';

const useOnboardingForm = () => {
  const userCreationState = useSelector((state: RootState) => state.userCreation);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loading = userCreationState.status === 'loading';
  const failed = userCreationState.status === 'failed';
  const [urlSearchParams] = useSearchParams();
  const [inviteRecipients, setInviteRecipients] = useState<string[]>(['']);
  const userEmail = decodeURIComponent(urlSearchParams.get(EMAIL_PARAM) || '');
  const verificationToken = urlSearchParams.get(VERIFICATION_TOKEN_PARAM) || '';
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const [captchaToken, setCaptchaToken] = useState('');
  const [market, setMarket] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [jobTitle, setJobTitle] = useState<string>('');
  const [companyName, setCompanyName] = useState<string>('');

  useEffect(() => {
    dispatch(resetStatus());
    handleEntrance();
  }, []);
  const sendInvites = async () => {
    const emailRecipients = inviteRecipients.filter(recipient => recipient.length);
    if (emailRecipients.length) {
      return await httpInstances.axiosGoInstance.post(
        `/share?verificationToken=${verificationToken}`,
        {
          recipient: emailRecipients,
          subject: 'You’re invited to join GX Go!',
          template: 'go_share_gxgo_email.ftl'
        }
      );
    }
  };
  const createNewUser = async () => {
    const user: User = {
      email: userEmail,
      hs_firstname: firstName,
      hs_lastname: lastName,
      hs_jobtitle: jobTitle,
      hs_company: companyName,
      prefMarket: market
    };
    return await dispatch(createUser({ user, captchaToken, verificationToken }))
      .unwrap()
      .then(response => {
        if (response.data && response.data.token && response.status === 200)
          setGxTokenToStorage(response.data.token);
      });
  };
  const captureOnboardingEvent = async () => {
    const user: User = {
      email: userEmail,
      hs_firstname: firstName,
      hs_lastname: lastName,
      hs_jobtitle: jobTitle,
      hs_company: companyName,
      prefMarket: market
    };
    posthog.capture('gxgo_onboarding_submitted', {
      user: user,
      inviteRecipients: inviteRecipients.filter(recipient => recipient && recipient.trim() !== '')
    });
  };

  const handleSubmit = () => {
    Promise.all([sendInvites(), createNewUser(), captureOnboardingEvent()]).then(() =>
      navigate('/markets/' + market)
    );
    setRefreshReCaptcha(r => !r);
  };

  const onVerify = useCallback((token: string) => {
    setCaptchaToken(token);
  }, []);

  const handleEntrance = () => {
    initializePostHog();
    identifyUserInPostHog(userEmail, null, null);
    const gxToken = getGxTokenFromStorage();
    if (gxToken) navigate('/markets');
  };

  const [activeStep, setActiveStep] = useState(0);

  const steps = [
    {
      component: (
        <AboutUser
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          companyName={companyName}
          setCompanyName={setCompanyName}
        />
      )
    },
    {
      component: (
        <DefaultMarket
          market={market}
          setMarket={setMarket}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
        />
      )
    },
    {
      component: (
        <Invite
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          inviteRecipients={inviteRecipients}
          setInviteRecipients={setInviteRecipients}
        />
      )
    },
    {
      component: (
        <JobTitle
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          jobTitle={jobTitle}
          setJobTitle={setJobTitle}
          handleSubmit={handleSubmit}
        />
      )
    }
  ];
  return { activeStep, steps, refreshReCaptcha, loading, failed, onVerify };
};
export default useOnboardingForm;

import { TextField, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import Paper from '@mui/material/Paper';
import { useEffect, useState } from 'react';
import { EmailOutlined } from '@mui/icons-material';
import posthog from 'posthog-js';
import { RootState } from '../../../store';
import Dialog from 'components/Dialog';
import { retrieveFromUserMetadata } from 'utils/commonFunctions';
import { METADATA_FIRST_NAME_FIELD, METADATA_LAST_NAME_FIELD } from 'utils/constants';

const PremiumDialog = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  const [value, setValue] = useState('');
  const [messageSent, setMessageSent] = useState(false);
  const { metadata, userEmail } = useSelector((state: RootState) => state.auth);

  useEffect(
    () => () => {
      setTimeout(() => {
        setMessageSent(false);
        setValue('');
      }, 1000);
    },
    [open]
  );

  const firstName = retrieveFromUserMetadata(metadata || [], METADATA_FIRST_NAME_FIELD);
  const lastName = retrieveFromUserMetadata(metadata || [], METADATA_LAST_NAME_FIELD);

  const handleClose = () => {
    onClose && onClose();
  };

  const handleAction = () => {
    if (messageSent) {
      handleClose();
      return;
    }
    setMessageSent(true);
    posthog.capture('premium_enquire_sent', {
      additionalInfo: value
    });
  };

  return (
    <Dialog
      sx={{ zIndex: 1402 }}
      text={
        messageSent ? (
          <>
            <Typography sx={{ fontSize: 20, fontWeight: 700, mb: 0.7 }}>Message sent!</Typography>
            <Typography sx={{ mt: 2 }}>Thank you.</Typography>
            <Typography>We'll be in touch shortly!</Typography>
          </>
        ) : (
          <>
            <Typography sx={{ fontSize: 20, fontWeight: 700, mb: 0.7 }}>
              Enquire about Premium
            </Typography>
            <Typography sx={{ fontWeight: 700, mb: 0.7 }}>
              {firstName} {lastName}
            </Typography>
            {userEmail && <Typography sx={{ fontWeight: 700 }}>{userEmail}</Typography>}
            <Paper sx={{ p: 1.5, my: 2, fontSize: 14 }}>
              Hi,
              <br />
              Can you tell me more about GX Go Premium?
            </Paper>
            <TextField
              hiddenLabel
              multiline
              minRows={2}
              fullWidth
              value={value}
              onChange={e => setValue(e.target.value)}
              variant='outlined'
              placeholder='(Optional) Enter any additional information'
            />
          </>
        )
      }
      actionButtonText={
        messageSent ? (
          'Close'
        ) : (
          <>
            <EmailOutlined />
            Send
          </>
        )
      }
      hasCancelButton={!messageSent}
      open={open}
      handleClose={handleClose}
      handleAction={handleAction}
    />
  );
};

export default PremiumDialog;

import { useEffect, useState } from 'react';
import { Box, SxProps, Theme } from '@mui/material';
import { UnauthenticatedMessage } from 'pages/Facthsheets/FactsheetView/FactsheetWindow/FactsheetBody';
import { downlaodFileFromDatalake } from 'utils/commonFunctions';
import Loader from 'components/Loader';
import { FactsheetDetails } from 'pages/Facthsheets/constants';

const Flowchart = ({
  flowchartFileId,
  boxStyles
}: {
  flowchartFileId: string;
  boxStyles?: SxProps<Theme>;
}) => {
  const [imageSrc, setImageSrc] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    downlaodFileFromDatalake(flowchartFileId)
      .then(response => {
        if (response.status === 200) {
          const imgUrl = URL.createObjectURL(response.data);
          setImageSrc(imgUrl);
        } else setError('Flowchart file not found');
      })
      .catch(error => {
        console.error('Error fetching image:', error);
        setError('Error fetching image:' + error);
      });
  }, [location]);

  if (!error) {
    return (
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          ...boxStyles
        }}
      >
        {imageSrc ? (
          <img
            src={imageSrc}
            alt='factsheetImage'
            style={{ width: '90%' }}
          />
        ) : (
          <Box
            sx={{
              width: '100%',
              height: 250
            }}
          >
            <Loader />
          </Box>
        )}
      </Box>
    );
  }
};
const FlowchartTab = ({
  factsheet,
  gxToken
}: {
  factsheet: FactsheetDetails;
  gxToken: string | null;
}) => {
  const flowchartIds: string[] =
    factsheet.factsheetVersionHistory.length > 0
      ? (factsheet.factsheetVersionHistory[0].flowcharts ?? [])
      : [];

  const flowchartId1 =
    flowchartIds.length > 0 && flowchartIds[0] !== 'false' ? flowchartIds[0] : undefined;
  const flowchartId2 =
    flowchartIds.length > 1 && flowchartIds[1] !== 'false' ? flowchartIds[1] : undefined;

  if (!gxToken) {
    return (
      <Box sx={{ pt: 5 }}>
        <UnauthenticatedMessage />
      </Box>
    );
  }
  return (
    <Box>
      {flowchartId1 && <Flowchart flowchartFileId={flowchartId1} />}
      {flowchartId2 && (
        <Flowchart
          boxStyles={{ mt: 4 }}
          flowchartFileId={flowchartId2}
        />
      )}
    </Box>
  );
};
export default FlowchartTab;

import { useRef, useState } from 'react';
import { Box, ButtonBase, Link, TableCell, TableRow, useTheme } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp, LockOutlined } from '@mui/icons-material';
import posthog from 'posthog-js';
import { CommoditiesTableRowType, CommoditiesTableSectionType } from '../../../types';
import useSelectionFunctions from '../../../../../../components/SymbolSelector/useSelectionFunctions';
import useCommoditiesSelection from '../../useCommoditiesSelection';
import CollapsedRow from './CollapsedRow';
import { formatNumberWithPrecision, getDiffColor } from 'utils/commonFunctions';
import DateText from 'components/DateText';
import { DateTextVariants } from 'components/DateText/useDateText';
import Tooltip from 'components/Tooltip';
import IndeterminateCheckbox from 'components/IndeterminateCheckbox';
import CustomCheckbox from 'components/Checkbox';
import { createSelectedSymbol } from 'slices/symbolSelectorSlice';

const CommoditiesTableRow = ({
  row,
  sections
}: {
  row: CommoditiesTableRowType;
  sections: CommoditiesTableSectionType[];
}) => {
  const theme = useTheme();
  const {
    shortTitle,
    date,
    last,
    delta,
    diff,
    diffDelta,
    symbol: { key },
    diffSymbol: { key: diffKey }
  } = row;
  const lastSymbol = createSelectedSymbol(key);
  const diffSymbol = createSelectedSymbol(diffKey);
  const restrictedData = !last && !diff;
  const rowRef = useRef<HTMLTableRowElement | null>(null);

  const returnBackgroundColor = () => {
    if (restrictedData) return theme.palette.table.infoRowBg;
    return 'inherit';
  };
  const [open, setOpen] = useState(false);
  const { isSymbolSelected, toggleSymbolInSelection } = useSelectionFunctions();
  const { toggleCodeInSelection } = useCommoditiesSelection(sections);
  const trackTableRowClick = (row: CommoditiesTableRowType) => {
    posthog.capture('table_row_click', {
      symbol: row.symbol,
      diffSymbol: row.diffSymbol
    });
  };
  const restrictedIconWithTooltip = (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
      <Tooltip
        title={
          <>
            You don't have access to this.{' '}
            <Link
              href='https://www.general-index.com/contact-us'
              target='_blank'
              rel='noopener noreferrer'
              color='text.primary'
              sx={{ fontWeight: 700 }}
            >
              Request access
            </Link>
          </>
        }
      >
        <LockOutlined sx={{ fontSize: '15px', color: '#cccccc' }} />
      </Tooltip>
    </Box>
  );

  return (
    <>
      <TableRow
        onClick={() => trackTableRowClick(row)}
        className='markets-table-row'
        key={shortTitle}
        ref={rowRef}
        sx={{
          '&:last-child td, &:last-child th': { pb: 1 },
          '& td, & th': { backgroundColor: returnBackgroundColor() },
          '&:first-of-type td, &:first-of-type th': { pt: 1 }
        }}
      >
        <TableCell
          sx={theme => ({
            p: 0,
            pl: 1,
            pr: 1,
            borderLeft: open ? `2px solid ${theme.palette.primary.main}` : 'none'
          })}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ButtonBase
              disabled={restrictedData}
              aria-label='expand row'
              style={{ paddingRight: 5 }}
              onClick={() => setOpen(!open)}
            >
              {open ? (
                <KeyboardArrowUp sx={{ fontSize: 14 }} />
              ) : (
                <KeyboardArrowDown sx={{ fontSize: 14 }} />
              )}
            </ButtonBase>
            {lastSymbol.Code && diffSymbol.Code ? (
              <IndeterminateCheckbox
                firstSelected={isSymbolSelected(lastSymbol)}
                secondSelected={isSymbolSelected(diffSymbol)}
                disabled={restrictedData}
                onChange={() => {
                  toggleCodeInSelection(
                    row,
                    isSymbolSelected(lastSymbol) && isSymbolSelected(diffSymbol)
                  );
                }}
              />
            ) : null}
            {lastSymbol.Code && !diffSymbol.Code ? (
              <CustomCheckbox
                checked={isSymbolSelected(lastSymbol)}
                disabled={restrictedData}
                onChange={() => {
                  toggleSymbolInSelection(lastSymbol, isSymbolSelected(lastSymbol));
                }}
              />
            ) : null}{' '}
            {!lastSymbol.Code && diffSymbol.Code ? (
              <CustomCheckbox
                checked={isSymbolSelected(diffSymbol)}
                disabled={restrictedData}
                onChange={() => {
                  toggleSymbolInSelection(diffSymbol, isSymbolSelected(diffSymbol));
                }}
              />
            ) : null}
          </Box>
        </TableCell>
        <TableCell
          component='th'
          scope='row'
          sx={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          {shortTitle}
        </TableCell>
        <TableCell align='right'>
          <DateText
            dateString={date}
            variant={DateTextVariants.DAY_MONTH}
          />
        </TableCell>
        <TableCell align='right'>
          {restrictedData ? restrictedIconWithTooltip : last ? formatNumberWithPrecision(last) : ''}
        </TableCell>
        <TableCell
          align='right'
          sx={{ color: getDiffColor(Number(delta)) }}
        >
          {delta ? formatNumberWithPrecision(delta) : ''}
        </TableCell>
        <TableCell align='right'>
          {restrictedData ? restrictedIconWithTooltip : diff ? formatNumberWithPrecision(diff) : ''}
        </TableCell>
        <TableCell
          align='right'
          sx={{ color: getDiffColor(Number(diffDelta)), pr: 2 }}
        >
          {diffDelta ? formatNumberWithPrecision(diffDelta) : ''}
        </TableCell>
      </TableRow>
      <CollapsedRow
        open={open}
        row={row}
        lastSymbol={lastSymbol}
        diffSymbol={diffSymbol}
      />
    </>
  );
};

export default CommoditiesTableRow;

import { EmailOutlined, LinkOutlined, ShareOutlined } from '@mui/icons-material';
import { Box, Button, Dialog, DialogContent, Divider, TextField, Typography } from '@mui/material';
import useShareButton from './useShareButton';

const ShareButton = ({ title, additionalTitle }: { title?: string; additionalTitle?: string }) => {
  const {
    modalOpen,
    setModalOpen,
    content,
    setContent,
    recipient,
    handleChangeRecipient,
    copyToClipboard,
    sendEmail,
    emailError
  } = useShareButton();

  return (
    <>
      <Button
        variant='contained'
        color='secondary'
        sx={{ width: 150, height: 48 }}
        onClick={() => setModalOpen(true)}
      >
        <ShareOutlined />
        Share
      </Button>
      <Dialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        sx={{
          '& .MuiDialog-container': {
            backgroundColor: 'rgba(18, 22, 63, 0.40)'
          }
        }}
      >
        <DialogContent
          sx={{ backgroundColor: 'background.default', minWidth: 540, '& p': { fontWeight: 700 } }}
        >
          <Typography variant='h5'>
            Share {title} {additionalTitle && `/ ${additionalTitle}`}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1, mt: 3 }}>
            <LinkOutlined sx={{ mr: 1 }} />
            <Typography>Share a link</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ backgroundColor: '#4F545880', p: 1.7, fontSize: 14, flexGrow: 1 }}>
              {window.location.href}
            </Box>
            <Button
              variant='contained'
              color='primary'
              sx={{ ml: 1, height: 48, width: 120 }}
              onClick={copyToClipboard}
            >
              Copy link
            </Button>
          </Box>
          <Box sx={{ position: 'relative' }}>
            <Divider sx={{ my: 5, color: 'info.dark' }} />
            <Box
              sx={{
                backgroundColor: 'background.default',
                position: 'absolute',
                left: '50%',
                top: -16,
                fontSize: 12,
                p: 1,
                color: 'info.dark'
              }}
            >
              OR
            </Box>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <EmailOutlined sx={{ mr: 1 }} />
            <Typography>Share via email</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'start', height: 70 }}>
            <TextField
              sx={{
                fontSize: 14,
                flexGrow: 1,
                '& .MuiFilledInput-input': {
                  py: '14px!important'
                },
                '& .MuiInputBase-root': {
                  backgroundColor: '#4F545873'
                }
              }}
              hiddenLabel
              value={recipient}
              onChange={e => handleChangeRecipient(e.target.value)}
              variant='filled'
              placeholder="Enter the recipient's email..."
              error={!!emailError}
              helperText={emailError}
            />
            <Button
              variant='contained'
              color='primary'
              sx={{ ml: 1, height: 48, width: 120 }}
              onClick={sendEmail}
            >
              Send an email
            </Button>
          </Box>
          <Typography sx={{ my: 1, fontSize: 14 }}>Message (Optional)</Typography>
          <TextField
            hiddenLabel
            multiline
            rows={3}
            maxRows={5}
            fullWidth
            value={content}
            onChange={e => setContent(e.target.value)}
            variant='outlined'
            placeholder='Type your message...'
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ShareButton;

import { useSelector } from 'react-redux';
import { useAppDispatch } from 'hooks/redux-hooks';
import { RootState } from 'store';
import {
  addSymbol,
  addSymbolArray,
  deleteSymbol,
  SelectedSymbol,
  setLimitReached
} from 'slices/symbolSelectorSlice';
import { MAX_SELECTED_SYMBOLS } from 'utils/constants';

const useSelectionFunctions = () => {
  const dispatch = useAppDispatch();
  const symbolSelectorState = useSelector((state: RootState) => state.symbolSelector);

  const toggleSymbolInSelection = (symbol: SelectedSymbol, codeSelected: boolean): void => {
    if (codeSelected) removeSymbolFromSelection(symbol);
    else addSymbolToSelection(symbol);
  };

  const addAllSymbolsToSelection = (symbols: SelectedSymbol[]) => {
    symbols.forEach(symbol => addSymbolToSelection(symbol));
  };

  const allSymbolsSelected = (symbols: SelectedSymbol[]) => {
    return symbols.every(symbol => isSymbolSelected(symbol));
  };

  const removeSymbolsFromSelection = (symbols: Array<SelectedSymbol>) => {
    symbols.forEach(symbol => removeSymbolFromSelection(symbol));
  };

  const addSymbolToSelection = (symbol: SelectedSymbol) => {
    if (symbolSelectorState.symbols.length > MAX_SELECTED_SYMBOLS) {
      dispatch(setLimitReached(true));
    } else if (symbol.Code.length) {
      dispatch(addSymbol({ symbol }));
    }
  };

  const removeSymbolFromSelection = (symbol: SelectedSymbol) => {
    if (symbol.Code.length) {
      dispatch(deleteSymbol({ symbol }));
    }
  };

  const isSymbolSelected = (selectedSymbol: SelectedSymbol | undefined) => {
    if (!selectedSymbol) return false;
    return Boolean(
      symbolSelectorState.symbols.find(symbol => {
        return (
          symbol.Code === selectedSymbol.Code &&
          symbol.Period === selectedSymbol.Period &&
          symbol.PeriodType === selectedSymbol.PeriodType &&
          symbol.TimeRef === selectedSymbol.TimeRef
        );
      })
    );
  };

  const addSymbolsArrayToSelection = (symbols: SelectedSymbol[]) => {
    if (symbols.length + symbolSelectorState.symbols.length > MAX_SELECTED_SYMBOLS) {
      dispatch(setLimitReached(true));
    } else {
      dispatch(addSymbolArray({ symbols }));
    }
  };

  const noneSelected = symbolSelectorState.symbols.length < 1;

  return {
    toggleSymbolInSelection,
    addAllSymbolsToSelection,
    allSymbolsSelected,
    removeSymbolFromSelection,
    removeSymbolsFromSelection,
    isSymbolSelected,
    addSymbolsArrayToSelection,
    addSymbolToSelection,
    noneSelected
  };
};

export default useSelectionFunctions;

import StockChart from 'pages/Insights/InsightsComponents/ChartsBlock/StockChartsBlock/StockChart';
import useChartSeries from 'pages/Insights/InsightsComponents/ChartsBlock/useChartSeries';
import { ChartSeriesType } from 'pages/Insights/InsightsComponents/types';

const AuthenticatedChart = ({
  templateChartSeries,
  code
}: {
  templateChartSeries: ChartSeriesType[];
  code: string;
}) => {
  const { loading, error, chartSeries } = useChartSeries(templateChartSeries);
  return (
    <StockChart
      height={14}
      chartSeries={chartSeries}
      error={error}
      loading={loading}
      parent={{
        mainPageTitle: 'factsheets',
        tabName: code
      }}
      showLegend={false}
    />
  );
};
export default AuthenticatedChart;

import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { CropSquareOutlined, FileDownloadOutlined } from '@mui/icons-material';
import useUserAccess from 'hooks/useUserAccess';
import UpgradeToPremiumButton from 'pages/Launchpad/UpgradeToPremiumButton';
import { downloadPDF, getGxTokenFromStorage } from 'utils/commonFunctions';
import { FactsheetDetails, FACTSHEETS_ENDPOINT } from 'pages/Facthsheets/constants';
import ShareButton from 'pages/Insights/ShareButton';
import useItemsTab from 'pages/Facthsheets/FactsheetView/FactsheetWindow/FactsheetBody/Tabs/ItemsTab/useItemsTab';

const FactsheetHeader = ({ factsheet }: { factsheet: FactsheetDetails }) => {
  const navigate = useNavigate();
  const gxToken = getGxTokenFromStorage();
  const { checkUserHasPremium } = useUserAccess();
  const { handleAllItemsSelection, allItemsSelected } = useItemsTab(factsheet);
  const UnauthenticatedButtonSection = () => {
    return (
      <>
        <Button
          sx={{ mr: 1.5 }}
          variant='contained'
          onClick={() => {
            navigate('/login');
          }}
        >
          <Typography
            variant='body2'
            sx={{ fontWeight: 600, width: 200 }}
          >
            Register for GX Go
          </Typography>
        </Button>
      </>
    );
  };

  const AuthenticatedButtonSection = () => {
    return (
      <>
        {!checkUserHasPremium() && <UpgradeToPremiumButton sx={{ mr: 1.5 }} />}
        <ShareButton title={factsheet.title} />
        <Button
          variant='contained'
          color='secondary'
          sx={{ height: 48, mx: 1.5 }}
          disabled={allItemsSelected()}
          onClick={() => {
            handleAllItemsSelection();
          }}
        >
          <CropSquareOutlined /> <Typography variant='caption'>Add item(s) to selector</Typography>
        </Button>
        <Button
          variant='contained'
          color='secondary'
          onClick={() => {
            downloadPDF(`${FACTSHEETS_ENDPOINT}/${factsheet.code}.pdf`, factsheet.code + '.pdf');
          }}
        >
          <FileDownloadOutlined />
          <Typography variant='caption'>Download factsheet</Typography>
        </Button>
      </>
    );
  };
  const validFrom =
    factsheet.factsheetVersionHistory.length > 0 &&
    factsheet.factsheetVersionHistory[0].factsheetValidFrom
      ? factsheet.factsheetVersionHistory[0].factsheetValidFrom
      : '';
  const validTo =
    factsheet.factsheetVersionHistory.length > 0 &&
    factsheet.factsheetVersionHistory[0].factsheetValidTo
      ? factsheet.factsheetVersionHistory[0].factsheetValidTo
      : '';

  return (
    <Box sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', mt: 1.5 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', flexWrap: 'wrap' }}>
        <Box sx={{ flexGrow: 1, mr: 6, maxWidth: 740 }}>
          <Typography
            variant='h5'
            sx={{ fontWeight: 700, fontSize: 24 }}
          >
            {factsheet.title}
          </Typography>
        </Box>
        <Box
          sx={{ py: 2 }}
          display='flex'
        >
          {gxToken ? <AuthenticatedButtonSection /> : <UnauthenticatedButtonSection />}
        </Box>
      </Box>
      <Box sx={{ height: 148 }}>
        <Typography variant='h6'>{factsheet.description}</Typography>
      </Box>
      <Typography
        variant='body1'
        sx={{ pt: 1 }}
      >
        Factsheet version {factsheet.latestFactsheetVersion}
        {validFrom && `, valid from ${validFrom}`}
        {validTo && ` to ${validTo}`}
      </Typography>
    </Box>
  );
};
export default FactsheetHeader;

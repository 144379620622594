import { Box, Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { ArrowBack, DashboardOutlined } from '@mui/icons-material';
import { useEffect } from 'react';
import useUserDetails from 'hooks/useUserAccess';

const buttonStyles = {
  width: 250,
  display: 'flex',
  justifyContent: 'flex-start',
  textTransform: 'none',
  mb: 3,
  backgroundColor: 'info.light',
  '&:hover': {
    backgroundColor: 'info.dark'
  }
};

const SuccessScreen = ({
  setExported,
  setPreviewOptionId
}: {
  setExported: (newExported: boolean) => void;
  setPreviewOptionId: (newId: string) => void;
}) => {
  useEffect(() => () => setExported(false), []);
  const { getUserEmail } = useUserDetails();
  const userEmail = getUserEmail();

  return (
    <Box
      sx={{
        mt: 12,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Typography
        fontSize='24px'
        sx={{ textAlign: 'center' }}
      >
        We're on it.
        <br /> You will get an email shortly to
      </Typography>
      <Typography
        fontSize='24px'
        sx={{ mt: 2, mb: 4, textDecoration: 'underline' }}
      >
        {userEmail}
      </Typography>
      <Button
        sx={buttonStyles}
        variant='contained'
        startIcon={<ArrowBack />}
        onClick={() => {
          setExported(false);
          setPreviewOptionId('');
        }}
      >
        Back to Data Hub
      </Button>
      <Button
        sx={buttonStyles}
        variant='contained'
        component={Link}
        to='/markets'
        startIcon={<DashboardOutlined />}
      >
        Go to Markets
      </Button>
      {/*<Button*/}
      {/*  sx={{ ...buttonStyles, justifyContent: 'space-between' }}*/}
      {/*  variant='contained'*/}
      {/*  startIcon={<ArrowBack />}*/}
      {/*>*/}
      {/*  All Data Hubs*/}
      {/*  <ExpandMore />*/}
      {/*</Button>*/}
    </Box>
  );
};

export default SuccessScreen;

import { Cell } from '@tanstack/react-table';
import { Box, Link, useTheme } from '@mui/material';
import { LockOutlined } from '@mui/icons-material';
import { formatNumberWithPrecision, getDiffColor } from 'utils/commonFunctions';
import Tooltip from 'components/Tooltip';
import { CatalogItem } from 'slices/catalogSlice';
import CustomCheckbox from 'components/Checkbox';
import useSelectionFunctions from 'components/SymbolSelector/useSelectionFunctions';
import { SelectedSymbol } from 'slices/symbolSelectorSlice';
import useCatalogTableCell from 'pages/Catalog/CatalogTable/CatalogTableRow/useCatalogTableCell';
import FactsheetDialogButton from 'components/FactsheetDialogButton';

const SpotCatalogCell = ({
  cell,
  isRestrictedData,
  symbol
}: {
  cell: Cell<CatalogItem, unknown>;
  isRestrictedData: boolean;
  symbol?: SelectedSymbol;
}) => {
  const { isSymbolSelected, toggleSymbolInSelection } = useSelectionFunctions();
  const columnId = cell.column.id;
  const cellValue = cell.getValue() as string;

  const { palette } = useTheme();
  const { renderContent, defaultStyle } = useCatalogTableCell(columnId, cell);

  const returnCellColor = (isChangeColumn?: boolean, value?: string) => {
    if (isRestrictedData) return palette.table.infoRowText;
    if (isChangeColumn) return getDiffColor(Number(value));
    return 'inherit';
  };
  const spotStyle = {
    ...defaultStyle,
    justifyContent: ['Last', 'Change'].includes(columnId) ? 'flex-end' : 'flex-start',
    color: returnCellColor(columnId === 'Change', cellValue)
  };

  const renderCellContent = () => {
    switch (columnId) {
      case 'Symbol': {
        return (
          <CustomCheckbox
            disabled={isRestrictedData}
            checked={isSymbolSelected(symbol as SelectedSymbol)}
            onChange={() => {
              toggleSymbolInSelection(
                symbol as SelectedSymbol,
                isSymbolSelected(symbol as SelectedSymbol)
              );
            }}
          />
        );
      }
      case 'Index':
        return cellValue === 'Y' ? 'Index' : 'High, Mid, Low';
      case 'Last':
      case 'Change':
        if (isRestrictedData) {
          return (
            <Tooltip
              title={
                <>
                  You don't have access to this.{' '}
                  <Link
                    href='https://www.general-index.com/contact-us'
                    target='_blank'
                    rel='noopener noreferrer'
                    color='text.primary'
                    sx={{ fontWeight: 700 }}
                  >
                    Request access
                  </Link>
                </>
              }
            >
              <LockOutlined
                sx={{ fontSize: '16px' }}
                color='inherit'
              />
            </Tooltip>
          );
        } else return <>{formatNumberWithPrecision(cellValue)}</>;
      case 'Factsheet':
        return (
          <Box
            width={'100%'}
            display={'flex'}
            justifyContent={'center'}
          >
            <FactsheetDialogButton code={cell.row.getValue('Code')} />
          </Box>
        );
      default:
        return renderContent();
    }
  };

  return (
    <div
      key={cell.id + columnId}
      style={spotStyle}
    >
      {renderCellContent()}
    </div>
  );
};

export default SpotCatalogCell;

import { useEffect, useState } from 'react';
import { Option } from 'slices/userConfigSlice';

const useSettingsChanger = ({
  options,
  onChange,
  userSetting
}: {
  options: Option[];
  onChange: (userSetting: Option) => void;
  userSetting: Option;
}) => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState({ value: '', label: '' });
  const selectedIndex = options.findIndex(
    option => option.label === selected.label && option.value === selected.value
  );

  useEffect(() => {
    const userSelected = options.find(option => option.value === userSetting.value);
    if (userSelected) setSelected(userSelected);
  }, [userSetting.value]);

  const handleMenuItemClick = (index: number) => {
    setSelected(options[index]);
    onChange(options[index]);
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return {
    selectedIndex,
    open,
    handleMenuItemClick,
    handleOpen,
    handleClose,
    selected
  };
};

export default useSettingsChanger;

import { useEffect } from 'react';
import { Box } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch } from 'hooks/redux-hooks';
import { loginTrip } from 'slices/authSlice';
import { getGxTokenFromStorage, setGxTokenToStorage } from 'utils/commonFunctions';
import Loader from 'components/Loader';

const Home = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [urlSearchParams] = useSearchParams();
  const urlToken = urlSearchParams.get('token');
  const gxToken = getGxTokenFromStorage();

  useEffect(() => {
    handleSetup();
  }, [urlToken]);

  const handleSetup = () => {
    if (gxToken) navigate('/markets');
    else handleLoginTrip();
  };

  const handleLoginTrip = async () => {
    if (!urlToken) {
      navigate('/login');
    } else {
      const data = await dispatch(loginTrip(urlToken));
      if (data?.meta.requestStatus !== 'rejected') {
        setGxTokenToStorage(urlToken);
        navigate('/markets');
      }
    }
  };
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '100vh'
      }}
    >
      <Loader />
    </Box>
  );
};

export default Home;

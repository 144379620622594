import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import FactsheetHeader from './FactsheetHeader';
import FactsheetBody from './FactsheetBody';
import httpInstances from 'api/axiosInstance';
import Loader from 'components/Loader';
import { FactsheetDetails } from 'pages/Facthsheets/constants';
import { parseBackendData } from 'utils/commonFunctions';

export const FactsheetWindow = ({
  factsheetKey,
  openNewTab,
  updateTabName
}: {
  factsheetKey: string;
  openNewTab: (code: string) => void;
  updateTabName: (code: string, newName: string) => void;
}) => {
  const [factsheetDetails, setFactsheetDetails] = useState<FactsheetDetails | null>(null);

  const navigate = useNavigate();
  useEffect(() => {
    getFactsheet(factsheetKey).then(factsheets => {
      setFactsheetDetails(factsheets[0]);
      updateTabName(factsheetKey, factsheets[0].code);
    });
  }, [factsheetKey]);

  const getFactsheet = async (code: string): Promise<FactsheetDetails[]> => {
    return await httpInstances.axiosInternalInstance
      .get('/factsheets/details?code=' + code)
      .then(response => parseBackendData(response.data))
      .catch(error => {
        console.error('Error fetching factsheetDetails: ', error);
        navigate('/notfound');
      });
  };

  if (!factsheetDetails) {
    return (
      <Box sx={{ height: 700, width: 1000 }}>
        <Loader />
      </Box>
    );
  }
  return (
    <Box sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
      <FactsheetHeader factsheet={factsheetDetails} />
      <FactsheetBody
        factsheet={factsheetDetails}
        openNewTab={openNewTab}
      />
    </Box>
  );
};
export default FactsheetWindow;

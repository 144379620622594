import { Dispatch, SetStateAction, useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { emailValidator } from 'utils/validators';
import StepButtons from 'pages/Onboarding/OnboardingForm/Steps/StepButtons';

const Invite = ({
  activeStep,
  setActiveStep,
  inviteRecipients,
  setInviteRecipients
}: {
  inviteRecipients: string[];
  setInviteRecipients: Dispatch<SetStateAction<string[]>>;
  activeStep: number;
  setActiveStep: Dispatch<SetStateAction<number>>;
}) => {
  const [emailErrors, setEmailErrors] = useState<string[]>(['']);

  const validateEmail = () => {
    const errors = inviteRecipients.map(email => {
      if (email.length) {
        const errorMessage = emailValidator(email);
        if (errorMessage) return errorMessage;
      }
      return '';
    });
    setEmailErrors(errors);
    return !errors.filter(email => email.length > 0).length;
  };
  const emailInputs = inviteRecipients.filter(email => email.length);
  emailInputs.push('');

  return (
    <Box>
      <Typography sx={{ fontSize: 24, fontWeight: 'bold', mb: 1.5 }}>
        Data is more valuable when it’s shared!
      </Typography>
      <Typography sx={{ fontSize: 20, fontWeight: 'bold', mb: 1.5 }}>
        Invite your teammates to collaborate...
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {emailInputs.map((workEmail, index) => {
          return (
            <TextField
              key={index}
              variant='filled'
              autoComplete={'inviteEmail' + index}
              id={'inviteEmail' + index}
              label='Work email'
              name='inviteEmail'
              sx={{ minWidth: 220, mb: 1.5 }}
              value={workEmail}
              onChange={e => {
                let temp: string[] = inviteRecipients.map((email, emailsIndex) => {
                  if (emailsIndex === index) {
                    return e.target.value;
                  }
                  return email;
                });
                if (!inviteRecipients[index]) {
                  temp = [...temp, ''];
                }
                setInviteRecipients(temp);
              }}
              error={!!emailErrors[index]}
              helperText={emailErrors[index]}
            />
          );
        })}
      </Box>
      <Box>
        <StepButtons
          activeStep={activeStep}
          onBackClick={() => {
            setActiveStep(activeStep - 1);
          }}
          nextDisabled={!emailInputs.filter(email => email.length > 0).length}
          onNextClick={() => {
            if (validateEmail()) setActiveStep(activeStep + 1);
          }}
        />
        <Button
          fullWidth
          variant='outlined'
          disabled={activeStep === 0}
          sx={{
            '&.MuiButton-outlined': {
              color: 'primary.main',
              borderColor: 'primary.main'
            },
            fontSize: 16,
            fontWeight: 600,
            mt: 1
          }}
          onClick={() => {
            setActiveStep(activeStep + 1);
          }}
        >
          Skip for now
        </Button>
      </Box>
    </Box>
  );
};
export default Invite;

import Highcharts from 'highcharts/highstock';
import { SelectChangeEvent } from '@mui/material';
import { useEffect, useState } from 'react';

const useLegendDropdown = ({ chart }: { chart: Highcharts.Chart }) => {
  const [selectedSeries, setSelectedSeries] = useState<Highcharts.Series[]>();
  const [allChartSeries, setAllChartSeries] = useState<Highcharts.Series[]>([]);
  const names = chart?.series?.map(item => item.name).join(',');
  useEffect(() => {
    setAllChartSeries(
      chart.series.filter((series: Highcharts.Series) => !series.name.includes('Navigator'))
    );
  }, [names]);

  const handleLegendItemClick = (e: SelectChangeEvent<unknown>, allItems: Highcharts.Series[]) => {
    const value: any = e.target.value;
    if (typeof value === 'object') {
      allItems?.map(item => {
        if (!value?.includes(item)) {
          item.setVisible(false);
        } else {
          item.setVisible(true);
        }
      });
      setSelectedSeries(allItems?.filter(item => item.visible));
    }
  };

  return { selectedSeries, allChartSeries, handleLegendItemClick };
};

export default useLegendDropdown;

import { Box, Dialog } from '@mui/material';
import FactsheetView from 'pages/Facthsheets/FactsheetView';

const FactsheetDialog = ({
  code,
  open,
  setOpen
}: {
  code: string;
  open: boolean;
  setOpen: (open: boolean) => void;
}) => {
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => setOpen(false)}
      sx={{ position: 'absolute' }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'stretch',
          justifyContent: 'center',
          height: '100%',
          width: '100%',
          backgroundColor: '#12163fcc'
        }}
      >
        <FactsheetView
          setOpen={setOpen}
          factsheetKeys={code}
        />
      </Box>
    </Dialog>
  );
};

export default FactsheetDialog;

import { useEffect, useState } from 'react';
import Highcharts from 'highcharts/highstock';
import bolinger from 'highcharts/indicators/bollinger-bands';
import dema from 'highcharts/indicators/dema';
import ema from 'highcharts/indicators/ema';
import regressions from 'highcharts/indicators/regressions';
import priceIndicator from 'highcharts/modules/price-indicator';
import fullScreen from 'highcharts/modules/full-screen';
import annotationsAdvanced from 'highcharts/modules/annotations-advanced.js';
import exporting from 'highcharts/modules/exporting';
import stockTools from 'highcharts/modules/stock-tools.js';
import indicators from 'highcharts/indicators/indicators';
import psar from 'highcharts/indicators/psar';
import tema from 'highcharts/indicators/tema';
import trendline from 'highcharts/indicators/trendline';
import apo from 'highcharts/indicators/apo';
import aroon from 'highcharts/indicators/aroon';
import arronOsc from 'highcharts/indicators/aroon-oscillator';
import cmo from 'highcharts/indicators/cmo';
import dpo from 'highcharts/indicators/dpo';
import di from 'highcharts/indicators/disparity-index';
import macd from 'highcharts/indicators/macd';
import ppo from 'highcharts/indicators/ppo';
import roc from 'highcharts/indicators/roc';
import rsi from 'highcharts/indicators/rsi';
import trix from 'highcharts/indicators/trix';
import { ChartSeriesType } from '../types';
import useChartSeries from './useChartSeries';

indicators(Highcharts);
annotationsAdvanced(Highcharts);
priceIndicator(Highcharts);
bolinger(Highcharts);
dema(Highcharts);
ema(Highcharts);
regressions(Highcharts);
psar(Highcharts);
tema(Highcharts);
trendline(Highcharts);
apo(Highcharts);
aroon(Highcharts);
arronOsc(Highcharts);
cmo(Highcharts);
dpo(Highcharts);
di(Highcharts);
macd(Highcharts);
ppo(Highcharts);
roc(Highcharts);
rsi(Highcharts);

trix(Highcharts);
fullScreen(Highcharts);
stockTools(Highcharts);
exporting(Highcharts);

const indicatorTitles = new Map([
  ['bb', 'Bollinger Bands'],
  ['linearRegression', 'Linear Regression'],
  ['linearRegressionAngle', 'Linear Regression Angle'],
  ['linearRegressionIntercept', 'Linear Regression Intercept'],
  ['linearRegressionSlope', 'Linear Regression Slope'],
  ['apo', 'Absolute Price'],
  ['atr', 'Average True Range'],
  ['natr', 'NATR']
]);

(function (H: any) {
  const seriesTypes: any = H.seriesTypes;
  for (const [key, value] of Object.entries(seriesTypes)) {
    const title = indicatorTitles.get(key);
    const type: any = value;
    if (title) {
      type.prototype.nameBase = title;
    }
  }
})(Highcharts);

const useChartsBlock = (tabs: any) => {
  const [activeTab, setActiveTab] = useState(0);
  const [fullScreen, setFullScreen] = useState(false);

  const [templateChartSeries, setTemplateChartSeries] = useState<ChartSeriesType[]>();

  const { loading, error, chartSeries } = useChartSeries(templateChartSeries);

  useEffect(() => {
    setActiveTab(0);
    setTemplateChartSeries(tabs[0].series);
  }, []);

  useEffect(() => {
    setTemplateChartSeries(tabs[activeTab].series);
  }, [activeTab]);

  return {
    activeTab,
    setActiveTab,
    fullScreen,
    setFullScreen,
    loading,
    error,
    chartSeries
  };
};

export default useChartsBlock;

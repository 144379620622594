import { Routes, Route } from 'react-router-dom';
import ProtectedLayout from 'layouts/ProtectedLayout';
import Home from 'pages/Home';
import Login from 'pages/Onboarding/Login';
import Insights from 'pages/Insights';
import Profile from 'pages/Profile';
import NotFound from 'pages/NotFound';
import Catalog from 'pages/Catalog';
import Launchpad from 'pages/Launchpad';
import { FWDCURVE_CATALOG_PATH, SPOT_CATALOG_PATH } from 'utils/constants';
import OnboardingForm from 'pages/Onboarding/OnboardingForm';
import Logout from 'pages/Logout';
import UnprotectedLayout from 'layouts/UnprotectedLayout';
import { FACTSHEET_PARAM_NAME } from 'pages/Facthsheets/constants';
import Factsheets from 'pages/Facthsheets';
import Onboarding from 'pages/Onboarding';

const AppRoutes = () => {
  return (
    <Routes>
      <Route
        path='/onboarding'
        element={<Onboarding />}
      />
      <Route
        path='/login'
        element={<Login />}
      />
      <Route
        path='/onboarding/form'
        element={<OnboardingForm />}
      />
      <Route
        path='/logout'
        element={<Logout />}
      />
      <Route element={<UnprotectedLayout />}>
        <Route
          index
          element={<Home />}
        />
        <Route
          path='/token'
          element={<Home />}
        />
        <Route
          path={`/:${FACTSHEET_PARAM_NAME}`}
          element={<Factsheets />}
        />
        <Route element={<ProtectedLayout />}>
          <Route
            path='/markets'
            element={<Insights />}
          />
          <Route
            path='/markets/:insightId'
            element={<Insights />}
          />
          <Route
            path='markets/:insightId/:tabId'
            element={<Insights />}
          />
          <Route
            path={'/catalog/' + SPOT_CATALOG_PATH}
            element={<Catalog />}
          />
          <Route
            path={'/catalog/' + FWDCURVE_CATALOG_PATH}
            element={<Catalog />}
          />
          <Route
            path='/data-hub/*'
            element={<Launchpad />}
          />
          <Route
            path='/profile'
            element={<Profile />}
          />
        </Route>
      </Route>
      <Route
        path='/notfound'
        element={<NotFound />}
      />
      <Route
        path='/:section/*'
        element={<NotFound />}
      />
      <Route
        path='*'
        element={<NotFound />}
      />
    </Routes>
  );
};

export default AppRoutes;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import httpInstances from '../api/axiosInstance';
import { RootState } from '../store';
import { TileType } from 'pages/Insights/InsightsComponents/Tiles/types';
import {
  ChartsBlockTabType,
  CommoditiesTableTabType
} from 'pages/Insights/InsightsComponents/types';
import { detectEnvironment } from 'utils/commonFunctions';

type InsightsState = {
  status: 'idle' | 'loading' | 'failed' | 'fulfilled';
  error: string | null;
  insights: InsightsType | null;
};

type ErrorResponse = {
  message: string;
};

export type InsightsType = {
  metadata: {
    displayName: string;
    commodity: string[];
    region: string[];
  };
  pages: InsightsPageType[];
};

export type InsightsPageType = {
  name: string;
  components: InsightsComponentType[];
};

export type InsightsComponentType = {
  type: 'table' | 'tiles' | 'graph';
  layout?: {
    fullWidth?: boolean;
    height?: number;
  };
  tiles?: TileType[];
  tabs?: CommoditiesTableTabType[] | ChartsBlockTabType[];
};

const initialState: InsightsState = {
  status: 'idle',
  error: null,
  insights: null
};

export const getInsights = createAsyncThunk(
  'getInsights',
  async ({ insightId, timezone }: { insightId: string; timezone: string }, { rejectWithValue }) => {
    try {
      const { data } = await httpInstances.axiosInternalInstance.get(
        `${detectEnvironment() === 'staging' ? '/staging/' : '/'}insights/${insightId}?timezone=${timezone}`
      );
      return data;
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;
        return rejectWithValue(errorResponse);
      }
      throw error;
    }
  }
);

const insightsSlice = createSlice({
  name: 'insights',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getInsights.pending, state => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(getInsights.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.insights = action.payload;
      })
      .addCase(getInsights.rejected, (state, action) => {
        state.status = 'failed';
        if (action.payload) {
          state.error = (action.payload as ErrorResponse).message || 'Request failed';
        } else {
          state.error = action.error.message || 'Request failed';
        }
      });
  }
});

export const selectInsightsStatus = (state: RootState) => state.insights.status;
export default insightsSlice.reducer;
